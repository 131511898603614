import { FormGroup } from "@angular/forms";
import { isArray } from "util";
import { Utils } from "../../../shared/services/utils.service";
import * as FullL from '../polyline.data';

export class PolylineFeatures {

    static generatePolyline(trail:any,color:string,edit_with_drag:boolean,useSnake:boolean){
        let polyline;
        if(useSnake){
            polyline = FullL.Leaf.polyline(trail, { draggable: edit_with_drag,zIndex:100, interactive: edit_with_drag,color:color, smoothFactor: 0, noClip: true, edit_with_drag: edit_with_drag, snakingSpeed: 500 })
        }
        else{
            polyline = FullL.Leaf.polyline(trail, { draggable: edit_with_drag,zIndex:100, interactive: edit_with_drag,color:color, smoothFactor: 0, noClip: true, edit_with_drag: edit_with_drag})
        }
        if(!polyline){
            throw "Polyline is null. How did this happen?";
        }
        return polyline;
    }

    static generateTrail = (object, tmpRoute) => {
        let trailPoints: Map<number, any> = new Map<number, any>();

		if(object.trail_points)
		{
			for (let trailPoint of (object ? object.trail_points : tmpRoute)) {
				let orderIndex = parseInt(trailPoint['order_number']);
				trailPoints.set(orderIndex, [trailPoint.latitude, trailPoint.longitude]);
			}
		}
		else if (object.trail)
		{
			for (let trailPoint of (object ? object.trail : tmpRoute)) {
				let orderIndex = parseInt(trailPoint['order_number']);
				trailPoints.set(orderIndex, [trailPoint.latitude, trailPoint.longitude]);
			}
		}

        let orderedKeys = Array.from(trailPoints.keys()).sort(function (a, b) {
            return a - b;
        });

        let trailPointsArray = [];
        orderedKeys.forEach(key => {
            trailPointsArray.push(trailPoints.get(key));
        });

        return trailPointsArray;
    }

    static undoLastRoutePoint(canUndo: boolean, tmpRoute: any, map, object, form, activePolyline) {
        if (!canUndo) {
            return false;
        }
        // let points = activePolyline._latlngs;
        // if (points.length == 1) {
        //     if (object) {
        //         object.trail_points = [];
        //     }
        //     else {
        //         tmpRoute = [];
        //     }
        // }
        // else {
        //     let lastIndex = points.length;
        //     (object ? object.trail_points : tmpRoute).splice(lastIndex - 1, lastIndex);
        // }

        let trail = [];
        let idx = 0;
        for (let segment of activePolyline._latlngs) {
          trail.push({
            lat: segment.lat,
            lng: segment.lng,
            order_number:idx
          });
          idx++;
        }
        trail.splice(trail.length-1,trail.length);
        activePolyline._latlngs = trail;
        form.markAsTouched();
        return true;
    }

    static onRouteDrag(polyline) {
        polyline.on("dragend", function (e) {
            console.log(e);
        });
    }

    static deleteRoutePoint() {

    }

    static onRouteClick(polyline) {
        // polyline.on("click", function (e) {
        //     console.log(e);
        // });
        polyline.on(
            'click',
            function (e) {
                console.log("onRouteClick: ",e);
                FullL.Leaf.DomEvent.stopPropagation(e);
            });
    }

}