import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ModalDirective } from 'ngx-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { Page } from '../../../shared/models/page.model';
import { DotMenuComponent } from '../dot-menu/dot-menu.component';

@Component({
  selector: 'codeblack-table',
  templateUrl: './codeblack-table.component.html',
  styleUrls: ['./codeblack-table.component.scss']
})
export class TableComponent implements OnInit, AfterViewInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  ColumnMode = ColumnMode;
  page: Page = new Page();
  currentPage = 1;
  @Input() columns = [];
  rows = [];

  @Input() fileMode:boolean = false;
  @Input() parentSourceId:number;
  @Input() activeActionTitle: string;
  @Input() onReload: EventEmitter<boolean>;
  @Input() canClick: boolean = true;
  @Input() params: any;
  @Input() title: string;
  @Input() isLoading: boolean = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onParamChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBulk: EventEmitter<any> = new EventEmitter<any>();
  @Input() service: any;
  @Input() routerLinkReference: string;
  @Input() activeRows: Map<number, any>;
  @Input() dotMenuItems:any[];
  class:string = "codeblack-anchor";

  constructor(
    private permissionService: NgxPermissionsService
  ) { }

  ngAfterViewInit() {
    this.table.bodyComponent.updatePage = function (direction: string): void {
      let offset = this.indexes.first / this.pageSize;

      if (direction === 'up') {
        offset = Math.ceil(offset);
      } else if (direction === 'down') {
        offset = Math.floor(offset);
      }

      if (direction !== undefined && !isNaN(offset)) {
        this.page.emit({ offset });
      }
    }
  }

  activeDotMenus: Map<number, DotMenuComponent> = new Map<number, DotMenuComponent>();
  handleDotMenu(event, row, dotMenu) {
    this.activeDotMenus.forEach((value, key) => {
      if (value && value.state && key != row['id']) {
        value.state = false;
      }
    });
    this.activeDotMenus.set(row['id'], dotMenu);
  }

  @Input() handleButtonPress;

  ngOnInit() {
    let _this = this;
    this.loadRows();
    if (this.onReload) {
      this.onReload.subscribe(res => {
        _this.loadRows();
      })
    }
  }

  submitToggle(){
    
  }

  clearFilters() {
    this.table.sorts = [];
    this.order_by = null;
    this.order_asc_desc = null;
    this.currentPage = 1;
    delete this.params['order_by'];
    delete this.params['order_asc_desc'];
    this.params['page'] = 1;
    this.onParamChange.emit(this.params);
  }

  setPage(pageInfo) {
    this.currentPage = pageInfo.offset + 1;
    this.params.page = this.currentPage;
    this.onParamChange.emit(this.params);
    this.loadRows();
  }

  onFocus(event) {
    //console.log(event);
    var ua = navigator.userAgent;

    if(event['id']){
      if (this.canClick) {
        this.onClick.emit(event);
      }
    }
    else if (event['type'] == 'click') {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)){
        if (this.canClick) {
          this.onClick.emit(event['row']);
        }
      }
    }
  }

  @Input() rowFunction;

  subscription: Subscription;
  timeout:any;
  loadRows() {
    let _this = this;
    _this.rows = [];
    _this.isLoading = true;
    if(_this.order_by){
      _this.table.sorts = [{dir: _this.order_asc_desc, prop: _this.order_by}]
    }

    if(_this.subscription){
      _this.subscription.unsubscribe();
    }

    if (_this.timeout){
      clearTimeout(_this.timeout);
    }

    _this.timeout = setTimeout(() => {
      _this.subscription = (_this.parentSourceId ? (_this.fileMode ? _this.service.getAllFiles(_this.parentSourceId) : _this.service.getAll(_this.parentSourceId)) : _this.service.getAll(_this.params)).subscribe(res => {
        _this.subscription = null;
        _this.page = res['meta'];
        _this.rows = [];
        //console.log(res['data']['data'])
        for (let row of res['data']) {
          _this.rows.push(_this.rowFunction(row));
        }
        console.log(_this.rows);
        _this.isLoading = false;
      },
        err => {
          _this.subscription = null;
          _this.isLoading = false;
          console.log(err);
        });
    }, 350);
  }

  order_by: string;
  order_asc_desc: string;
  onSort(event) {
    this.params['order_by'] = event['column']['prop'];
    this.params['order_asc_desc'] = event['newValue'];
    this.order_by = event['column']['prop'];
    this.order_asc_desc = event['newValue'];
    this.onParamChange.emit(this.params);
    this.loadRows();
  }

  mainToggle: boolean = false;
  toggleAll() {
    let _this = this;
    this.mainToggle = !this.mainToggle;
    for (let item of _this.rows) {
      if (this.mainToggle == true) {
        _this.activeRows.set(item.id, item);
      }
      else {
        _this.activeRows.delete(item.id);
      }
    }
    this.onBulk.emit(true);
  }

  toggle(row) {
    row['bulk_checked'] = !row['bulk_checked'];
    let _this = this;
    if (row['bulk_checked']) {
      _this.activeRows.set(row['id'], row);
    }
    else {
      _this.activeRows.delete(row['id']);
    }
    this.onBulk.emit(true);
    //this.updateMenuItems();
  }

  isToggled = (row) => {
    return this.activeRows.has(row['id']);
  }

  @ViewChild('bulkModal', { static: true }) bulkModal: ModalDirective;
  openBulk() {
    this.bulkModal.show()
  }

}