import { EventEmitter } from "@angular/core";
import { ColumnModel } from "../../shared/models/column.model";
import { StaticPageModel } from "../../shared/models/static-page.model";
export class StaticPageBuildData{
    module = {
        name: "Static Page",
        new_name: "static-page",
        filtered_name:"Static Page",
        has_dot_menu: true,
        icon:'fa-browser',
        permission:'static_page'
    }
    
    onReload: EventEmitter < boolean > = new EventEmitter<boolean>();//Forces Reload of codeblack table on emit.
    rowFunction = (row:StaticPageModel) => {//Generates Row for codeblack-table
        return StaticPageModel.getListRow(row);
    }
    
    columns:ColumnModel[] = [
        {
            title: "Id",
            prop: "id",
            flex_grow: 1,
            row_accessor: "id",
            class:""//force-flex force-flex-end
        },
        {
            title: "Title",
            prop: "title",
            flex_grow: 4,
            row_accessor: "title",
        }
    ];

    constructor(){
        for(let col of this.columns){
            col = new ColumnModel(col);
        }
    }

}