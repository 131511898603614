import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'loader-spinner',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderSpinnerComponent implements OnInit {

  @Input() isLoading:boolean;
  @Input() classes:string = "transition-holder";
  @Output() submitEvent:EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) { }

  ngOnInit() {
    
  }


}