import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { UserService } from '../../../shared/services/user.service';
import { UserModel } from '../../../shared/models/user.model';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { ActionModel, MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import * as $ from 'jquery';
import { UserBuildData } from '../user.build-data';
import { PermissionService } from '../../../shared/services/permission.service';
import { PermissionGroupService } from '../../../shared/services/permission-group.service';
import { PaginatedSelectComponent } from '../../components/paginated-select/paginated-select.component';
import { LocalStoreService } from '../../../shared/services/local-store.service';
import { Utils } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  @Input() external: boolean = false;
  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
  user: UserModel;
  roles: any[];
  @ViewChild('deleteModal', { static: true }) modalDelete: ModalDirective;
  @ViewChild('sendForgotModal', { static: true }) forgotModal: ModalDirective;
  errors: String[] = [];
  data = new UserBuildData();
  module = this.data.module;
  form: FormGroup;
  @ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];

  constructor(
    private service: UserService,
    public permissionGroupService: PermissionGroupService,
    private router: Router,
    private snapshot: ActivatedRoute,
    private authService: AuthService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlingService,
    private utils: Utils,
    private cdr: ChangeDetectorRef,
    private store: LocalStoreService
  ) { }

  ngOnInit() {
    let _this = this;
    this.createForm({});
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.messageService.remove();
      }
    });
    this.snapshot.paramMap.subscribe(res => {
      if (res['params']['id'] && !_this.external) {
        this.loadData(res['params']['id']);
      }
    })
  }

  menuItems: any[] = [
    { action: 'delete', message: 'Delete ' + this.module.name, class: '', icon: 'fal fa-trash', permissions: ['delete_' + this.module.permission] },
    { action: 'send-forgot', message: 'Send Forgot Password', class: '', icon: 'fal fa-envelope', permissions: ['view_' + this.module.permission] }
  ];

  handleButtonPress = (event) => {
    switch (event) {
      case "delete":
        this.modalDelete.show();
        break;
      case "send-forgot":
        this.forgotModal.show();
        break;
      default:
        console.log(event);
        break;
    }
  }

  getUserEmail(){
    let email = this.form.get('email').value;
    return email;
  }

  isSendingForgot:boolean = false;
  sendResetPassword(){
    let _this = this;
    let email = this.getUserEmail();
    this.isSendingForgot = true;
    this.authService.forgotToken(email).subscribe(res => {
      _this.isSendingForgot = false;
      this.utils.successMessage("Password Reset Email Sent.");
      _this.forgotModal.hide();
    },
    err => {
      _this.isSendingForgot = false;
      console.log(err);
    })
  }

  incomingGroupEvent: EventEmitter<any> = new EventEmitter<any>();
  incomingPaginatedSelectValue(fieldName, value) {
    let _this = this;
    switch (fieldName) {
      case 'groups':
        _this.form.get('groups').setValue(value);
        break;
    }
  }

  markTouched() {
    this.form.markAsTouched();
  }

  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;

  fileChangeEvent(fileInput: any) {
    let _this = this;
    Utils.handleFileChange(fileInput).subscribe(res => {
      let success = res.success;
      if (success) {
        _this.cardImageBase64 = res.cardImageBase64;
        _this.isImageSaved = res.isImageSaved;
        _this.form.get('photo').setValue(res.cardImageBase64);
      }
      else {
        console.log("Failed to load file.");
      }
    });
  }

  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
  }

  // @ViewChild('fromPicker', { static: true }) fromPicker: pgDatePickerComponent;
  // @ViewChild('toPicker', { static: true }) toPicker: pgDatePickerComponent;
  // fromDate: Date = new Date();
  // toDate: Date = moment(new Date()).add(1, 'hour').toDate();
  // forceRefresh(dateEvent) {
  //   let _this = this;
  //   this.utils.forceRefresh(dateEvent,this.fromDate,this.toDate,this.fromPicker,this.toPicker).subscribe(res => {
  //     _this.fromDate = res['fromDate'];
  //     _this.toDate = res['toDate'];
  //     _this.cdr.detectChanges();
  //   })
  // }

  // _disabledStartDate = (startValue) => {
  //   return this.utils._disabledStartDate(startValue,this.fromDate,this.toDate);
  // };

  // _disabledEndDate = (endValue) => {
  //   return this.utils._disabledEndDate(endValue,this.fromDate,this.toDate);
  // };

  createForm(input, form?) {
    input = input || {};
    form ? form : this.form = UserModel.getFormGroup(input);
  }

  openFileUpload(ele) {
    $(ele).click();
  }

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  loading = {
    isFirstLoad: false,
    isLoading: false
  };

  loadData(id) {
    let _this = this;
    let checkLoadingSpinner = () => {
      if (!_this.loading.isFirstLoad) {
        _this.loading.isFirstLoad = true;
        _this.loading.isLoading = true;
      }
    }

    checkLoadingSpinner();

    this.service.get(id).subscribe(res => {
      _this.user = res['data'];
      _this.createForm(_this.user);
      if (res['data']['groups']) {
        for (let permGroup of res['data']['groups']) {
          _this.incomingGroupEvent.emit(permGroup);
        }
      }
      _this.triggerFetch.emit(_this.form);
      _this.loading.isLoading = false;
    },
      err => {
        _this.loading.isLoading = false;
        console.log(err);
      });
  }

  //Variable & Function for <phone-input>
  triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  updateForm(event) {
    this.form = event;
  }

  delete() {
    let _this = this;
    _this.isLoading = true;
    this.utils.delete(this.service, this.user.id, this.module.name, "/" + this.module.new_name).subscribe(res => {
      _this.isLoading = false;
    });
  }

  isFormValueChecked(controlName) {
    return this.form.get(controlName).value;
  }

  @Input() isLoading: boolean = false;

  save() {
    let _this = this;
    _this.isLoading = true;
    console.log(this.form.getRawValue());
    Utils.forceTouchPaginatedSelects(this.paginatedSelects);
    Utils.handleFormSave(this.form, this.service, this.user, this.module.name, this.createForm, null).subscribe(res => {
      _this.isLoading = false;
      if (res['data']) {
        if (_this.external == true) {
          _this.saveEvent.emit(res['data']);
        }
        else {
          this.router.navigate(['/' + this.module.new_name + '/' + res['data']['id']]);
        }
        let user = _this.store.getItem('currentUser');
        if (user && user['id'] == res['data']['id']) {
          user['photo'] = res['data']['photo'];
          user['first_name'] = res['data']['first_name'];
          user['last_name'] = res['data']['last_name'];
          _this.store.setItem('currentUser', user);
        }
        _this.utils.successMessage(_this.module.name + ' saved.');
      }
      else {
        Utils.sendFormSaveError(res,this.module,this.utils);
      }
    })
  }

  //Photo Upload

  photoChangeEvent(fileInput: any) {
    let _this = this;
    Utils.handleFileChange(fileInput).subscribe(res => {
      let success = res.success;
      if (success) {
        _this.cardImageBase64 = res.cardImageBase64;
        _this.isImageSaved = res.isImageSaved;
        _this.form.get('photo').setValue(res.cardImageBase64);
      }
      else {
        console.log("Failed to load file.");
      }
    });
  }

}