import { FormBuilder, FormGroup, Validators } from "@angular/forms";

export class AdvertModel {

    id: number;
    title:string;
    description:string;
    valid_from:string;
    valid_to:string;
    image:string;

    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.title = input.title || '';
        this.description = input.description || '';
        this.valid_from = input.valid_from || '';
        this.valid_to = input.valid_to || '';
        this.image = input.image || '';
    }

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();
        return fb.group({
            title: [input.title,Validators.required],
            description: [input.description,Validators.required],
            valid_from: [input.valid_from,Validators.required],
            valid_to: [input.valid_to,Validators.required],
            image: [input.image,Validators.required],
        })
    }

    static getListRow(row: AdvertModel) {
        return {
            id: row.id,
            title:row.title
        };
    }

}