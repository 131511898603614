import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MenuNavService {

  menuItems:any[] = [
    {
      label: 'Users',
      //routerLink: '/administration',
      iconType: 'fal',
      iconName: 'fa-users',
      roles: 'view_user',
      routerLink: '/user',
    },
	{
		label: 'Route',
		routerLink: '/route',
		iconType: 'fal',
		iconName: 'fa-route',
		roles: 'view_route',
	},
	{
		label: 'Camino',
		routerLink: '/camino',
		iconType: 'fal',
		iconName: 'fa-street-view',
		roles: 'view_camino',
	},
	{
		label: 'Certificate',
		routerLink: '/certificate',
		iconType: 'fal',
		iconName: 'fa-certificate',
		roles: 'view_static_page',//TODO - Change to view_certificate when permission gets added.
	},
    {
		label: 'Static Pages',
		routerLink: '/static-page',
		iconType: 'fal',
		iconName: 'fa-browser',
		roles: 'view_static_page',
	},
    {
      label: 'Advert',
      routerLink: '/advert',
      iconType: 'fal',
      iconName: 'fa-ad',
      roles: 'view_advert',
    },
    {
      label: 'Pilgrim Guide',
      routerLink: '/pilgrim-guide',
      iconType: 'fal',
      iconName: 'fa-pray',
      roles: 'view_pilgrim_guide',
    },
	{
		label: 'POI Type',
		routerLink: '/poitype',
		iconType: 'fal',
		iconName: 'fa-route',
		roles: 'view_route_poi_type',
	}
	// {
	// 	label: 'Permission Group',
	// 	routerLink: '/group',
	// 	iconType: 'fal',
	// 	iconName: 'fa-user-tag',
	// 	roles: 'view_group',
	// }
	// {
    //   label: 'Device',
    //   routerLink: '/device',
    //   iconType: 'fal',
    //   iconName: 'fa-phone-laptop',
    //   roles: 'view_device',
    // },
    
  ];
  onMenuChange:EventEmitter<any> = new EventEmitter<any>();

  navigateToPossibleRoute() {
    for (let menuItem of this.menuItems) {
      if (this.authService.hasPermission(menuItem.roles)) {
        this.router.navigate([menuItem.routerLink]);
        console.log(menuItem);
        break;
      }
    }
  }

  constructor(
    private http : HttpClient,
    private router : Router,
    private authService : AuthService
  ) { }

    

}
