export class ColumnModel{

    title:string;
    prop: string;
    sortable?: boolean;
    flex_grow?: number;
    class?: string;
    row_accessor: string;
    toggle?: boolean;
    html_data?:boolean;
    pipe?:string;

    constructor(input?){
        input = input || {};
        if(!input.sortable){
            input.sortable = true;
        }
        if(!input.flex_grow){
            input.flex_grow = 1;
        }
        if(!input.class){
            input.class = '';
        }
        if(!input.toggle){
            input.toggle = false;
        }
        this.pipe = input.pipe;
        this.title = input.title;
        this.prop = input.prop;
        this.sortable = input.sortable;
        this.flex_grow = input.flex_grow;
        this.class = input.class;
        this.row_accessor = input.row_accessor;
        this.toggle = input.toggle;
    }


}