import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CaminoModel } from "./camino.model";

export class CertificateModel {

    id: number;
    first_name:string;
    last_name:string;
    email:string;
    payment:any;
    camino:CaminoModel;

    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.first_name = input.first_name || '';
        this.last_name = input.last_name || '';
        this.email = input.email || '';
        this.payment = input.payment || {};
        this.camino = input.camino || {};
    }

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();
        return fb.group({
            first_name: [input.first_name, Validators.required],
            last_name: [input.last_name, Validators.required],
            email: [input.email, Validators.required],
        })
    }

    static getListRow(row: CertificateModel) {
        return {
            id: row.id,
            name: row.first_name+' '+row.last_name,
            email: row.email,
            route: row.camino.route.title
        };
    }

}