import { EventEmitter } from "@angular/core";
import { ColumnModel } from "../../shared/models/column.model";
import { DeviceModel } from "../../shared/models/device.model";
export class DeviceBuildData{
    module = {
        name: "Device",
        new_name: "device",
        has_dot_menu: false,
        icon:'fa-phone-laptop',
        permission:'device'
    }
    
    onReload: EventEmitter < boolean > = new EventEmitter<boolean>();//Forces Reload of codeblack table on emit.
    rowFunction = (row:DeviceModel) => {//Generates Row for codeblack-table
        return DeviceModel.getListRow(row);
    }
    
    columns:ColumnModel[] = [
        {
            title: "Id",
            prop: "id",
            flex_grow: 1,
            row_accessor: "id",
            class:""//force-flex force-flex-end
        },
        {
            title: "Device Id",
            prop: "device_id",
            flex_grow: 4,
            row_accessor: "device_id",
        }
    ];

    constructor(){
        for(let col of this.columns){
            col = new ColumnModel(col);
        }
    }

}