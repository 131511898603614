import { EventEmitter } from "@angular/core";
import { ColumnModel } from "../../shared/models/column.model";
import { UserModel } from "../../shared/models/user.model";

export class UserBuildData{
    module = {
        name: "User",
        new_name: "user",
        has_dot_menu: true,
        icon:'fa-users',
        permission:'user'
    }
    
    onReload: EventEmitter < boolean > = new EventEmitter<boolean>();//Forces Reload of codeblack table on emit.
    rowFunction = (row:UserModel) => {//Generates Row for codeblack-table
        return UserModel.getListRow(row);
    }
    
    columns:ColumnModel[] = [
        {
            title: "Id",
            prop: "id",
            row_accessor: "id",
            toggle: false
        },
        {
            title: "Name",
            prop: "first_name",
            flex_grow: 2,
            row_accessor: "name"
        },
        {
            title: "Email",
            prop: "email",
            flex_grow: 2,
            row_accessor: "email"
        },
        {
            title: "Address",
            prop: "address_1",
            flex_grow: 2,
            row_accessor: "address"
        }
    ];

    constructor(){
        for(let col of this.columns){
            col = new ColumnModel(col);
        }
    }

}