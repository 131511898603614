import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouteRoutes } from './route.routing';
import { SharedModule } from '../../@pages/components/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { pgSelectModule} from '../../@pages/components/select/select.module';
import { MessageModule } from '../../@pages/components/message/message.module';
import { MessageService } from '../../@pages/components/message/message.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { pgSwitchModule } from '../../@pages/components/switch/switch.module';
import { TextMaskModule } from 'angular2-text-mask';
import { ButtonLoadingModule } from '../components/btn-loading/btn-loading.module';
import { PhoneInputModule } from '../components/phone-input/phone-input.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule, 
    SharedModule,
    NgxDatatableModule,
    pgSelectModule,
    MessageModule,
    ModalModule,
    PhoneInputModule,
    TextMaskModule,
    pgSwitchModule,
    ButtonLoadingModule,
    RouterModule.forChild(RouteRoutes),
  ],
  declarations: [],
  providers:[MessageService]
})
export class RouteModule {
  
}
