import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../@pages/components/shared.module';
import { pgSelectModule } from '../../@pages/components/select/select.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { Dashboard } from './dashboard/dashboard.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ProgressModule } from '../../@pages/components/progress/progress.module';
import { pgDatePickerModule } from '../../@pages/components/datepicker/datepicker.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule, 
    SharedModule,
    pgSelectModule,
    DashboardRoutingModule,
    NgxEchartsModule,
    NgxDatatableModule,
    pgDatePickerModule,
    ProgressModule
  ],
  declarations: [Dashboard]
})
export class DashboardModule { }
