import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import * as $ from 'jquery';
import { Utils } from '../../../shared/services/utils.service';
import { CaminoBuildData } from '../camino.build-data';
import { pgDatePickerComponent } from '../../../@pages/components/datepicker/datepicker.component';
import * as moment from 'moment';
import { PermissionService } from '../../../shared/services/permission.service';
import { PermissionGroupService } from '../../../shared/services/permission-group.service';
import { PaginatedSelectComponent } from '../../components/paginated-select/paginated-select.component';
import { CaminoModel } from '../../../shared/models/camino.model';
import { CaminoService } from '../../../shared/services/camino.service';
import { RouteDetailComponent } from '../../route/route-detail/route-detail.component';
import { PolylineFeatures } from '../../route/route-detail/route.polyline';
import * as FullL from '../../route/polyline.data';

@Component({
	selector: 'app-camino-detail',
	templateUrl: './camino-detail.component.html',
	styleUrls: ['./camino-detail.component.scss']
})
export class CaminoDetailComponent implements OnInit {

	@Input() external: boolean = false;
	@Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
	object: CaminoModel;
	roles: any[];
	@ViewChild('deleteModal', { static: true }) modalDelete: ModalDirective;
	errors: String[] = [];
	data = new CaminoBuildData();
	module = this.data.module;
	form: FormGroup;

	constructor(
		private service: CaminoService,
		private router: Router,
		private snapshot: ActivatedRoute,
		private authService: AuthService,
		private messageService: MessageService,
		private errorHandler: ErrorHandlingService,
		private utils: Utils,
		private cdr: ChangeDetectorRef,
		public permissionService: PermissionService
	) { }

	ngOnInit() {
		let _this = this;
		this.createForm({});
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.messageService.remove();
			}
		});
		this.snapshot.paramMap.subscribe(res => {
			if (res['params']['id'] && !_this.external) {
				this.loadData(res['params']['id']);
			}
		})
	}

	menuItems: any[] = [
		// { action: 'delete', message: 'Delete ' + this.module.name, class: '', icon: 'fal fa-trash', permissions: ['delete_' + this.module.permission] }
	];

	handleButtonPress = (event) => {
		switch (event) {
			case "delete":
				this.modalDelete.show();
				break;
			default:
				console.log(event);
				break;
		}
	}

	incomingEvent: EventEmitter<any> = new EventEmitter<any>();
	incomingPaginatedSelectValue(fieldName, value) {
		let _this = this;
		console.log(fieldName);
		console.log(this.form);
		_this.form.get(fieldName).setValue(value);
	}

	markTouched() {
		this.form.markAsTouched();
	}

	imageError: string;
	isImageSaved: boolean;
	cardImageBase64: string;

	fileChangeEvent(fileInput: any) {
		let _this = this;
		Utils.handleFileChange(fileInput).subscribe(res => {
			let success = res.success;
			if (success) {
				_this.cardImageBase64 = res.cardImageBase64;
				_this.isImageSaved = res.isImageSaved;
			}
			else {
				console.log("Failed to load file.");
			}
		});
	}

	@ViewChild('fromPicker', { static: true }) fromPicker: pgDatePickerComponent;
	@ViewChild('toPicker', { static: true }) toPicker: pgDatePickerComponent;
	fromDate: Date = new Date();
	toDate: Date = moment(new Date()).add(1, 'hour').toDate();
	forceRefresh(dateEvent) {
		let _this = this;
		Utils.forceRefresh(dateEvent, this.fromDate, this.toDate, this.fromPicker, this.toPicker).subscribe(res => {
			_this.cdr.detectChanges();
		})
	}

	_disabledStartDate = (startValue) => {
		return Utils._disabledStartDate(startValue, this.fromDate, this.toDate);
	};

	_disabledEndDate = (endValue) => {
		return Utils._disabledEndDate(endValue, this.fromDate, this.toDate);
	};

	removeImage() {
		this.cardImageBase64 = null;
		this.isImageSaved = false;
	}

	createForm(input, form?) {
		input = input || {};
		form ? form : this.form = CaminoModel.getFormGroup(input);
	}

	openFileUpload(ele) {
		$(ele).click();
	}

	hasPermission(permission) {
		return this.authService.hasPermission(permission);
	}

	isTouched(controlName) {
		return Utils.isFormTouched(this.form, controlName);
	}

	loading = {
		isFirstLoad: false,
		isLoading: false
	};

	@ViewChild('route', { static: false }) routeComponent: RouteDetailComponent;
	loadData(id) {
		let _this = this;
		let checkLoadingSpinner = () => {
			if (!_this.loading.isFirstLoad) {
				_this.loading.isFirstLoad = true;
				_this.loading.isLoading = true;
			}
		}

		checkLoadingSpinner();

		this.service.get(id).subscribe(res => {
			_this.object = new CaminoModel(res['data']);
			setTimeout(() => {
				_this.initiateMap();
				_this.routeComponent.object = _this.object.route;
				_this.routeComponent.initializeDataAfterLoad();
			}, 500);
			console.log(_this.object);
			_this.createForm(_this.object);
			_this.triggerFetch.emit(_this.form);

			

			_this.loading.isLoading = false;

		},
			err => {
				_this.loading.isLoading = false;
				console.log(err);
			});
	}

	//Variable & Function for <phone-input>
	triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	updateForm(event) {
		this.form = event;
	}

	delete() {
		let _this = this;
		_this.isLoading = true;
		this.utils.delete(this.service, this.object.id, this.module.name, "/" + this.module.new_name).subscribe(res => {
			_this.isLoading = false;
		});
	}

	isFormValueChecked(controlName) {
		return this.form.get(controlName).value;
	}

	@Input() isLoading: boolean = false;


	@ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];
	save() {
		let _this = this;
		_this.isLoading = true;
		Utils.forceTouchPaginatedSelects(_this.paginatedSelects);
		Utils.handleFormSave(this.form, this.service, this.object, this.module.name, this.createForm, null).subscribe(res => {
			_this.isLoading = false;
			if (res['data']) {
				if (_this.external == true) {
					_this.saveEvent.emit(res['data']);
				}
				else {
					this.router.navigate(['/' + this.module.new_name + '/' + res['data']['id']]);
				}
				_this.utils.successMessage(_this.module.name + ' saved.');
			}
			else {
				Utils.sendFormSaveError(res, this.module, this.utils);
			}
		})
	}

	public map;

	initiateMap() {
		let _this = this;
		//to prevent errors
		if (this.map) {
			//to remove any initialization of a previous map
			this.map.off();
			this.map.remove();
			//this.mapview =! this.mapview;
		}
		// tiles are used to load and display tile layers on the map.
		var originalTile = this.getMapStyling();
		// declaring the leaflet map
		this.map = FullL.Leaf.map('map', {
			//choose the default view coordinates
			center: [35.935328, 14.454524],
			//choose the zooming level
			zoom: 12,
			//to remove the attribution
			attributionControl: false,
			//to add predefined layers
			layers: originalTile,
			zoomControl: false
		});

		this.plotRoute();
	}

	plotRoute(fitToScreen?) {
		let _this = this;

		//console.log(generateTrail());
		if (_this.object) {
			let trailPointsArray = PolylineFeatures.generateTrail(_this.object, []);
			let polyline;
			polyline = PolylineFeatures.generatePolyline(trailPointsArray, '#b2ebf2', false, true);//FullL.Leaf.polyline(trailPointsArray, { draggable: _this.canDragRoute(), interactive: _this.canDragRoute(), smoothFactor: 0, noClip: true, edit_with_drag: _this.canDragRoute(), snakingSpeed: 500 });
			_this.map.fitBounds(polyline.getBounds());
			polyline.addTo(_this.map);

		}


	}

	getMapStyling() {
		return FullL.Leaf.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
			attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
			subdomains: 'abcd',
			maxZoom: 20
		});
	}


}
