import { EventEmitter } from "@angular/core";
import { ColumnModel } from "../../../shared/models/column.model";
import { GroupModel } from "../../../shared/models/permission-group.model";
export class GroupBuildData{
    module = {
        name: "Permission Group",
        new_name: "group",
        has_dot_menu: true,
        icon:'fa-user-tag',
        permission:'group'
    }
    
    onReload: EventEmitter < boolean > = new EventEmitter<boolean>();//Forces Reload of codeblack table on emit.
    rowFunction = (row:GroupModel) => {//Generates Row for codeblack-table
        return GroupModel.getListRow(row);
    }
    
    columns:ColumnModel[] = [
        {
            title: "Id",
            prop: "id",
            flex_grow: 1,
            row_accessor: "id",
            class:""//force-flex force-flex-end
        },
        {
            title: "Title",
            prop: "title",
            flex_grow: 1,
            row_accessor: "title",
        }
    ];

    constructor(){
        for(let col of this.columns){
            col = new ColumnModel(col);
        }
    }

}