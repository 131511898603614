import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, ViewChildren } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as FileSaver from 'file-saver';
import { MessageService } from '../../@pages/components/message/message.service';
import { PaginatedSelectComponent } from '../../view/components/paginated-select/paginated-select.component';
import * as moment from 'moment';
import { ErrorHandlingService } from './error-handling.service';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { isArray, isDate } from 'rxjs/internal-compatibility';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

@Injectable({
  providedIn: 'root'
})
export class Utils {

  //public didFirstLoad:boolean = false;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private errorHandler: ErrorHandlingService,
    private router: Router
  ) { }

  static sendFormSaveError(res,module,utils) {
    if(res['errors'] && res['errors'][0]){
      utils.errorMessage(res['errors'][0]);
    }
    else{
      utils.errorMessage((module.name ? module.name : module)+" save failed.");
    }
  }

  static euroPrefix = createNumberMask({
    prefix: '€ ',
    suffix: '',
    allowDecimal: true
  });

  static distancePrefix = createNumberMask({
    prefix: '',
    suffix: ' meters',
    allowDecimal: true
  });

  static durationPrefix = createNumberMask({
    prefix: '',
    suffix: ' minutes',
    allowDecimal: false
  });

  static getAbbreviatedText(text): string {
    text = text.split("  ").join(" ");
    let val: string = "";
    let split = text.split(" ");
    let formattedSplit = [];
    if (split.length > 1) {
      for (let v of split) {
        formattedSplit.push(v[0].toUpperCase());
      }
      val = formattedSplit.join("");
    }
    else {
      val = text[0].toUpperCase();
    }
    return val;
  }

  static getFormattedPeriod(startInput: Date, endInput: Date): string {
    if (moment(startInput).isSame(endInput, "year")) {
      if (moment(startInput).isSame(endInput, "M")) {
        if (moment(startInput).isSame(endInput, "D")) {
          return (
            moment(endInput).format("DD MMMM YYYY")
          );
        }
        else {
          return (
            moment(startInput).format("DD") +
            " - " +
            moment(endInput).format("DD MMMM YYYY")
          );
        }
      } else {
        return (
          moment(startInput).format("DD MMMM") +
          " - " +
          moment(endInput).format("DD MMMM YYYY")
        );
      }
    } else {
      if (moment(startInput).isSame(endInput, "M")) {
        return (
          moment(startInput).format("DD") +
          " - " +
          moment(endInput).format("DD MMMM YYYY")
        );
      } else {
        let dateStart = moment(startInput).format("DD MMMM YYYY");
        let dateEnd = moment(endInput).format("DD MMMM YYYY");
        return dateStart + " - " + dateEnd;
      }
    }
  }

  static clearPaginatedSelects(paginated_selects: PaginatedSelectComponent[]) {
    if (paginated_selects) {
      for (let paginatedSelect of paginated_selects['_results']) {
        let select: PaginatedSelectComponent = paginatedSelect;
        if (select && select.pg_select) {
          select.pg_select._selectedOption = null;
          select.pg_select._selectedOptions = new Set();
          select.pg_select._value = [];
        }
        select.form.reset();
        select.row = null;
        console.log(select);
      }
    }
  }

  static forceTouchPaginatedSelects(paginated_selects: PaginatedSelectComponent[]) {
    if (paginated_selects) {
      for (let paginatedSelect of paginated_selects['_results']) {
        let select: PaginatedSelectComponent = paginatedSelect;
        if (select && select.pg_select) {
          select.form.markAllAsTouched();
        }
      }
    }
  }

  static filterArrayByIds(idList: number[], array: any[]) {
    let filteredArray = array.filter(item => !idList.includes(item.id));
    let mapValues: Map<number, any> = new Map<number, any>();
    for (let val of filteredArray) {
      mapValues.set(val.id, val);
    }
    return Array.from(mapValues.values());
  }

  filteredParams: any = {};
  filterUpdateTimeout: any;
  pushFiltersToRouter(value, name, filteredParams): EventEmitter<any> {
    let pusher = new EventEmitter<any>();
    let _this = this;
    this.filteredParams = filteredParams;
    value = encodeURIComponent(value);
    let paramStream = "?";
    for (let param in _this.filteredParams) {
      if (!_this.filteredParams[param] || _this.filteredParams[param] == "null") {
        continue;
      }
      paramStream += param + "=" + _this.filteredParams[param] + "&";
    }
    if (_this.filterUpdateTimeout) {
      clearTimeout(_this.filterUpdateTimeout);
      _this.filterUpdateTimeout = null;
    }
    _this.filterUpdateTimeout = setTimeout(() => {
      pusher.emit(paramStream);
      //window.history.pushState(null,"",("transaction"+paramStream));
      clearTimeout(_this.filterUpdateTimeout);
      _this.filterUpdateTimeout = null;
    }, 500);
    return pusher;
  }

  static formatNumbersToFixedWithCommas(number) {
    var amount = parseFloat(number).toFixed(2);
    var formattedString = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedString;
  }

  exportTableDownload(table, params, endpoint?) {
    let url = table + `/export`;
    if (endpoint != null) {
      url = endpoint;
    }
    this.http.post(`${environment.baseUrl}/` + url, params).subscribe(res => {
      let f = res['data'];
      let csv = res['meta']['columns'].join(",") + "\n";
      for (let row in f) {
        var rowArr = [];
        for (let key in f[row]) {
          rowArr.push(f[row][key]);
        }
        csv += rowArr.join(",") + '\n';
      }
      console.log(csv);
      const blob = new Blob([csv], { type: "text/plain" });
      console.log(f);
      FileSaver.saveAs(blob, table + ".csv");
    },
      err => {
        console.log(err);
      })
  }

  submitToggle(activeActionTitle, messageService, searchParams, rows, tableName) {
    let _this = this;
    switch (activeActionTitle) {
      case 'Export Selected':
        messageService.remove();
        messageService.success("Exported Selected Records!", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        let recordIds: number[] = [];
        console.log(rows);
        for (let row of rows) {
          recordIds.push(row.id);
        }
        let params = { ...searchParams, list_all: false, records: recordIds }
        this.exportTableDownload(tableName, params);
        break;
      case 'Export All':
        messageService.remove();
        this.exportTableDownload(tableName, { ...searchParams, list_all: true, records: [] });
        messageService.success("Exported All Records!", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        break;
      default:
        messageService.error("Please select a bulk action!", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        break;
    }
  }

  successMessage(message, duration?) {
    this.messageService.remove();
    this.messageService.success(message, { Position: 'bottom-right', Style: 'simple', Duration: duration ? duration : 5000 });
  }

  static getFormattedAddress(row){
    return ((row.address_1 ? (row.address_2 || row.post_code ? row.address_1 + ', ' : row.address_1) : '') + (row.address_2 ? (row.post_code ? row.address_2 + ', ' : row.address_2) : '') + (row.post_code ? row.post_code : ''));
  }

  errorMessage(message, duration?) {
    this.messageService.remove();
    this.messageService.error(message, { Position: 'bottom-right', Style: 'simple', Duration: duration ? duration : 5000 });
  }

  static _disabledStartDate = (startValue, fromDate, toDate) => {
    if (!startValue) {
      fromDate = moment(toDate).subtract(1, 'd').toDate();
    }
    if (!toDate) {
      toDate = moment(fromDate).add(1, 'd').toDate();
    }
    return moment(startValue).isAfter(moment(toDate));
  };

  static _disabledEndDate = (endValue, fromDate, toDate) => {
    if (!endValue || !fromDate) {
      toDate = moment(fromDate).add(1, 'd').toDate();
    }
    return moment(endValue).isBefore(moment(fromDate));
  };

  delete(service, idToDelete, title, navigateTo?): EventEmitter<boolean> {
    let _this = this;
    let eventEmitter: EventEmitter<boolean> = new EventEmitter<any>();
    service.delete(idToDelete).subscribe(res => {
      this.router.navigate([navigateTo]);
      this.successMessage(title + " Deleted.");
      eventEmitter.emit(true);
    },
      err => {
        this.errorMessage(ErrorHandlingService.getErrors(err)[0]);
        console.log(err);
        eventEmitter.emit(false);
      });
    return eventEmitter;
  }
  
  publish(service, id, title, navigateTo?): EventEmitter<boolean> {
    let _this = this;
    let eventEmitter: EventEmitter<boolean> = new EventEmitter<any>();
    service.publish(id).subscribe(res => {
      this.router.navigate([navigateTo]);
      this.successMessage(title + " published.");
      eventEmitter.emit(true);
    },
      err => {
        this.errorMessage(ErrorHandlingService.getErrors(err)[0]);
        console.log(err);
        eventEmitter.emit(false);
      });
    return eventEmitter;
  }

  publishinternally(service, id, title, navigateTo?): EventEmitter<boolean> {
    let _this = this;
    let eventEmitter: EventEmitter<boolean> = new EventEmitter<any>();
    service.publishinternally(id).subscribe(res => {
      this.router.navigate([navigateTo]);
      this.successMessage(title + " published internally.");
      eventEmitter.emit(true);
    },
      err => {
        this.errorMessage(ErrorHandlingService.getErrors(err)[0]);
        console.log(err);
        eventEmitter.emit(false);
      });
    return eventEmitter;
  }

  unpublish(service, id, title, navigateTo?): EventEmitter<boolean> {
    let _this = this;
    let eventEmitter: EventEmitter<boolean> = new EventEmitter<any>();
    service.unpublish(id).subscribe(res => {
      this.router.navigate([navigateTo]);
      this.successMessage(title + " no longer published.");
      eventEmitter.emit(true);
    },
      err => {
        this.errorMessage(ErrorHandlingService.getErrors(err)[0]);
        console.log(err);
        eventEmitter.emit(false);
      });
    return eventEmitter;
  }

  unpublishinternally(service, id, title, navigateTo?): EventEmitter<boolean> {
    let _this = this;
    let eventEmitter: EventEmitter<boolean> = new EventEmitter<any>();
    service.unpublishinternally(id).subscribe(res => {
      this.router.navigate([navigateTo]);
      this.successMessage(title + " no longer published internally.");
      eventEmitter.emit(true);
    },
      err => {
        this.errorMessage(ErrorHandlingService.getErrors(err)[0]);
        console.log(err);
        eventEmitter.emit(false);
      });
    return eventEmitter;
  }

  static checkFormValidity(form: FormGroup, paginatedSelects?: PaginatedSelectComponent[]): boolean {
    form.updateValueAndValidity();
    if (paginatedSelects) {
      this.forceTouchPaginatedSelects(paginatedSelects);
    }
    if (form.invalid) {
      form.markAllAsTouched();
      return false;
    }

    form.markAsUntouched();
    form.updateValueAndValidity();
    return true;
  }

  static forceRefresh(dateEvent, fromDate, toDate, fromPicker, toPicker): EventEmitter<any> {
    let eventEmitter: EventEmitter<any> = new EventEmitter<any>();
    let _this = this;
    if (dateEvent.date) {
      if (moment(new Date(dateEvent.date)).isBefore(fromDate)) {
        dateEvent.date = fromDate;
      }
      if (moment(new Date(dateEvent.date)).isAfter(toDate)) {
        dateEvent.date = toDate;
      }
    }
    if (!fromDate && !toDate) {
      return;
    }

    if (moment(fromDate).isAfter(moment(toDate))) {
      toDate = moment(fromDate).add(1, 'h').toDate();
      console.log(toDate);
    }

    if (fromPicker) {
      if (fromPicker.timePickerInner) {
        fromPicker.timePickerInner.updateTime();
      }
    }
    if (toPicker) {
      if (toPicker.timePickerInner) {
        toPicker.timePickerInner.updateTime();
      }
    }
    setTimeout(() => {
      eventEmitter.emit({
        fromDate: fromDate,
        toDate: toDate
      });
    }, 500);
    return eventEmitter;
  }

  static handleFormFormat = (form) => {
    let rawValue = form.getRawValue();

    for (let v in rawValue) {
      if (isDate(rawValue[v])) {
        rawValue[v] = moment(rawValue[v]).format();
        console.log(v, "is Date.");
      }
      else if (rawValue[v] instanceof FormGroup) {
        rawValue[v] = rawValue[v].getRawValue();
      }
      else if (isArray(rawValue[v])) {
        let array = [];
        for (let innerV of rawValue[v]) {
          if (innerV instanceof FormGroup) {
            array.push(innerV.getRawValue());
          }
          else {
            array.push(innerV);
          }
        }
        rawValue[v] = array;
      }
      else if (rawValue[v] instanceof Object && rawValue[v]['id'] != null) {
        rawValue[v] = rawValue[v]['id'];
      }
    }
    return rawValue;
  }

  static handleFormSave(form: FormGroup, service, existingObject: any, name: string, createFunction: any, paginatedSelects?: PaginatedSelectComponent[]): EventEmitter<any> {
    let _this = this;
    let eventEmitter: EventEmitter<any> = new EventEmitter<any>();

    if (!this.checkFormValidity(form, paginatedSelects)) {
      setTimeout(() => {
        eventEmitter.emit(false);
        console.log("Form Invalid => ", form);
      }, 500);
      return eventEmitter;
    }

    let rawValue = this.handleFormFormat(form);

    if (rawValue['poi']) {
      for(let poi of rawValue['poi']){
        if(poi['type'] && poi['type']['id']){
          poi['type'] = poi['type']['id'];
        }
        if(poi['advert'] && poi['advert']['id']){
          poi['advert'] = poi['advert']['id'];
        }
        // if(!poi['type']){
        //   poi['type'] = 5;
        // }
      }
    }

    console.log(rawValue);

    if (existingObject) {
      service.edit(existingObject.id, rawValue).subscribe(res => {
        //_this.successMessage(name + " Saved.");
        //console.log(res['data']);
        createFunction(res['data'], form);
        //console.log(form);
        eventEmitter.emit({ data: res['data'] });
      },
        err => {
          let errors = ErrorHandlingService.getErrors(err);
          //_this.errorMessage(name + " save failed.");
          console.log(err);
          eventEmitter.emit({errors: errors});
        });
    }
    else {
      service.create(rawValue).subscribe(res => {
        //_this.successMessage(name + " Saved.");
        eventEmitter.emit({ data: res['data'] });
      },
        err => {
          let errors = ErrorHandlingService.getErrors(err);
          console.log(err);
          eventEmitter.emit({errors: errors});
        });
    }
    return eventEmitter;
  }

  static handleFileChange(fileInput: any): EventEmitter<any> {
    let data = {
      imageError: "",
      cardImageBase64: "",
      isImageSaved: false,
      success: false
    };
    let eventEmitter: EventEmitter<any> = new EventEmitter<any>();
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        data.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';
        data.success = false;
        eventEmitter.emit(data);
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          if (img_height > max_height && img_width > max_width) {
            data.imageError = 'Maximum dimentions allowed ' + max_height + '*' + max_width + 'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            data.cardImageBase64 = imgBase64Path;
            data.isImageSaved = true;
          }
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
    data.success = true;
    setTimeout(() => {
      eventEmitter.emit(data);
    }, 500);
    return eventEmitter;
  }

  static isFormTouched(form, controlName, hasPassword?: boolean) {
    if (hasPassword) {
      form.get('password').clearValidators();
      form.get('password_confirmation').clearValidators();
      form.get('password').updateValueAndValidity();
      form.get('password_confirmation').updateValueAndValidity();
    }
    try {
      if (form.get(controlName).touched && form.get(controlName).invalid) {
        return true;
      }
      else {
        return false;
      }
    }
    catch (err) {
//       console.log(controlName);
    }
  }

  static getRandomColor() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  static generateColorList() {
    // let colorList = [];
    // for (let i = 0; i < 10; i++) {
    //   colorList.push(this.getRandomColor());
    // }
    let colorList = ["#DB7B6D","#1E386B","#5b7fff","#38cb89","#ffab00","#ff5630","#377DFF","#5272b3","#515d75","#03a9f4"];
    return colorList;
  }

  static getBarStack(name,data) {
    return {
      symbolSize: 10,
      name: name,
      type: 'bar',
      stack: 0,
      z: 0,
      label: {
        normal: {
          position: 'top'
        }
      },
      barGap: "-100%",
      areaStyle: {
      },
      data: data
    };
  }

  static getBarChart() {
    return {
      color: this.generateColorList(),
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'cross',
              label: {
                  backgroundColor: '#6a7985'
              }
          }
      },
      legend: {
        orient: 'horizontal',
        left: 'left',
        data: []
      },
      toolbox: {
          feature: {
          }
      },
      grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
      },
      xAxis: [
          {
              type: 'category',
              //boundaryGap: false,
              data: [],
              // axisLabel: {
              //     interval: 0,
              //     rotate: 45 //If the label names are too long you can manage this by rotating the label.
              // }
          }
      ],
      yAxis: [
          {
              type: 'value'
          }
      ],
      series: []
  };
    // return {
    //   color: this.generateColorList(),
    //   tooltip: {
    //     trigger: 'axis',
    //     axisPointer: {
    //       type: 'cross',
    //       label: {
    //         backgroundColor: '#6a7985'
    //       }
    //     }
    //   },
    //   legend: {
    //     data: []//'Total','Subtotal','VAT'

    //   },
    //   toolbox: {
    //     feature: {
    //     }
    //   },
    //   grid: {
    //     left: '3%',
    //     right: '4%',
    //     bottom: '3%',
    //     containLabel: true
    //   },
    //   xAxis: [
    //     {
    //       type: 'category',
    //       data: []//names
    //     }
    //   ],
    //   yAxis: [
    //     {
    //       type: 'value'
    //     }
    //   ],
    //   series: []
    // };
  }

  static getPieChart() {
    return {
      color: this.generateColorList(),
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)'
      },
      legend: {
        orient: 'horizontal',
        left: 'left',
        data: []
      },
      toolbox: {
        feature: {
        }
      },
      series: [
        {
          name: 'Employee Hours',
          type: 'pie',
          radius: ['50%', '70%'],
          center: ['50%', '60%'],
          data: {},
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
  }

}
