import { Component } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { environment } from '../environments/environment';
import { LocalStoreService } from './shared/services/local-store.service';

@Component({
  selector: 'app-root',
  // template: '<router-outlet></router-outlet>',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private authService : AuthService,
    private store : LocalStoreService){
    //myUndefinedFunction();
  }

  getLogo() {
    return this.authService.getLogo();
  }

  getFavicon() {
    return this.authService.getFavicon();
  }

  isLoggedIn(){
    return this.authService.authenticated;
  }

  isAuthenticated() {
    if (this.authService.initialTimeout || !this.authService.didFirstCheck) {
      return false;
    }
    return this.authService.authenticated;
  }

}
