import { EventEmitter } from "@angular/core";
import { ColumnModel } from "../../shared/models/column.model";
import { RouteModel } from "../../shared/models/route.model";
export class DeviceBuildData{
    module = {
        name: "Route",
        new_name: "route",
        has_dot_menu: true,
        icon:'fa-route',
        permission:'route'
    }
    
    onReload: EventEmitter < boolean > = new EventEmitter<boolean>();//Forces Reload of codeblack table on emit.
    rowFunction = (row:RouteModel) => {//Generates Row for codeblack-table
        return RouteModel.getListRow(row);
    }
    
    columns:ColumnModel[] = [
        {
            title: "Id",
            prop: "id",
            flex_grow: 1,
            row_accessor: "id",
            class:""//force-flex force-flex-end
        },
        {
            title: "Title",
            prop: "title",
            flex_grow: 4,
            row_accessor: "title",
        },
		{
            title: "Published State",
            prop: "publish",
            flex_grow: 3,
            row_accessor: "publish",
        }
    ];

    constructor(){
        for(let col of this.columns){
            col = new ColumnModel(col);
        }
    }

}