import { Routes } from '@angular/router';
import { AuthGaurd } from '../../shared/guards/auth-guard.service';
import { RoleGuard } from '../../shared/guards/role-guard';
import { PilgrimGuideDetailComponent } from './pilgrim-guide-detail/pilgrim-guide-detail.component';
import { PilgrimGuideListComponent } from './pilgrim-guide-list/pilgrim-guide-list.component';

export const AdvertRoutes: Routes = [
  {
    path: '',
    component: PilgrimGuideListComponent
  },
  {
    path: 'new',
    canActivate: [AuthGaurd,RoleGuard],
    component: PilgrimGuideDetailComponent
  },
  {
    path: ':id',
    canActivate: [AuthGaurd,RoleGuard],
    component: PilgrimGuideDetailComponent
  },
];
