import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(
        private http: HttpClient
    ) { }

    getPieProject(params?){
        return this.http.get(`${environment.baseUrl}/dashboard/piechart/project`,{params:params});
    }

    getPieWorks(params?){
        return this.http.get(`${environment.baseUrl}/dashboard/piechart/works`,{params:params});
    }

    getPieEmployee(params?){
        return this.http.get(`${environment.baseUrl}/dashboard/piechart/employee`,{params:params});
    }

    getBarProject(params?){
        return this.http.get(`${environment.baseUrl}/dashboard/barchart/project`,{params:params});
    }

    getBarEmployees(params?){
        return this.http.get(`${environment.baseUrl}/employee/report`,{params:params});
        //return this.http.get(`${environment.baseUrl}/dashboard/barchart/employee`,{params:params});
    }

}
