import { Routes } from '@angular/router';
import { AuthGaurd } from '../../shared/guards/auth-guard.service';
import { RoleGuard } from '../../shared/guards/role-guard';
import { DeviceDetailComponent } from './device-detail/device-detail.component';
import { DeviceListComponent } from './device-list/device-list.component';

export const DeviceRoutes: Routes = [
  {
    path: '',
    component: DeviceListComponent
  },
  {
    path: 'new',
    canActivate: [AuthGaurd,RoleGuard],
    component: DeviceDetailComponent
  },
  {
    path: ':id',
    canActivate: [AuthGaurd,RoleGuard],
    component: DeviceDetailComponent
  },
];
