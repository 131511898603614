import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileRoutes } from './profile.routing';
import { SharedModule } from '../../@pages/components/shared.module';
import { ProfileComponent } from './profile.component';
import { MessageService } from '../../@pages/components/message/message.service';
import { pgSwitchModule } from '../../@pages/components/switch/switch.module';
import { TextMaskModule } from 'angular2-text-mask';
import { pgSelectModule } from '../../@pages/components/select/select.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MessageModule } from '../../@pages/components/message/message.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ButtonLoadingModule } from '../components/btn-loading/btn-loading.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule, 
    SharedModule,
    pgSelectModule,
    MessageModule,
    ModalModule,
    TextMaskModule,
    pgSwitchModule,
    RouterModule.forChild(ProfileRoutes),
    ButtonLoadingModule
  ],
  declarations: [ProfileComponent],
  providers:[MessageService]
})
export class ProfileModule { }
