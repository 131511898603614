import * as L from 'leaflet';

export var Leaf = L;

(function () {
  "use strict";
  
  /**
   * @fileOverview Leaflet Geometry utilities for distances and linear referencing.
   * @name L.GeometryUtil
   */
  
  L.GeometryUtil = L.extend(L.GeometryUtil || {}, {
  
      /**
          Shortcut function for planar distance between two {L.LatLng} at current zoom.
          @param {L.Map} map
          @param {L.LatLng} latlngA
          @param {L.LatLng} latlngB
          @returns {Number} in pixels
       */
          distance: function (map, latlngA, latlngB) {
              return map.latLngToLayerPoint(latlngA).distanceTo(map.latLngToLayerPoint(latlngB));
          },
  
      /**
          Shortcut function for planar distance between a {L.LatLng} and a segment (A-B).
          @param {L.Map} map
          @param {L.LatLng} latlng
          @param {L.LatLng} latlngA
          @param {L.LatLng} latlngB
          @returns {Number} in pixels
      */
      distanceSegment: function (map, latlng, latlngA, latlngB) {
          var p = map.latLngToLayerPoint(latlng),
             p1 = map.latLngToLayerPoint(latlngA),
             p2 = map.latLngToLayerPoint(latlngB);
          return L.LineUtil.pointToSegmentDistance(p, p1, p2);
      },
  
      /**
          Returns true if the latlng belongs to segment.
          param {L.LatLng} latlng
          @param {L.LatLng} latlngA
          @param {L.LatLng} latlngB
          @param {?Number} [tolerance=0.2]
          @returns {boolean}
       */
      belongsSegment: function(latlng, latlngA, latlngB, tolerance) {
          tolerance = tolerance === undefined ? 0.2 : tolerance;
          var hypotenuse = latlngA.distanceTo(latlngB),
              delta = latlngA.distanceTo(latlng) + latlng.distanceTo(latlngB) - hypotenuse;
          return delta/hypotenuse < tolerance;
      },
  
      /**
       * Returns total length of line
       * @param {L.Polyline|Array<L.Point>|Array<L.LatLng>}
       * @returns {Number} in meters
       */
      length: function (coords) {
          var accumulated = L.GeometryUtil.accumulatedLengths(coords);
          return accumulated.length > 0 ? accumulated[accumulated.length-1] : 0;
      },
  
      /**
       * Returns a list of accumulated length along a line.
       * @param {L.Polyline|Array<L.Point>|Array<L.LatLng>}
       * @returns {Number} in meters
       */
      accumulatedLengths: function (coords) {
          if (typeof coords.getLatLngs == 'function') {
              coords = coords.getLatLngs();
          }
          if (coords.length === 0)
              return [];
          var total = 0,
              lengths = [0];
          for (var i = 0, n = coords.length - 1; i< n; i++) {
              total += coords[i].distanceTo(coords[i+1]);
              lengths.push(total);
          }
          return lengths;
      },
  
      /**
          Returns the closest point of a {L.LatLng} on the segment (A-B)
          @param {L.Map} map
          @param {L.LatLng} latlng
          @param {L.LatLng} latlngA
          @param {L.LatLng} latlngB
          @returns {L.LatLng}
      */
      closestOnSegment: function (map, latlng, latlngA, latlngB) {
          var maxzoom = map.getMaxZoom();
          if (maxzoom === Infinity)
              maxzoom = map.getZoom();
          var p = map.project(latlng, maxzoom),
             p1 = map.project(latlngA, maxzoom),
             p2 = map.project(latlngB, maxzoom),
             closest = L.LineUtil.closestPointOnSegment(p, p1, p2);
          return map.unproject(closest, maxzoom);
      },
  
      /**
          Returns the closest latlng on layer.
          @param {L.Map} map
          @param {Array<L.LatLng>|L.PolyLine} layer - Layer that contains the result.
          @param {L.LatLng} latlng
          @param {?boolean} [vertices=false] - Whether to restrict to path vertices.
          @returns {L.LatLng}
      */
      closest: function (map, layer, latlng, vertices) {
          if (typeof layer.getLatLngs != 'function')
              layer = L.polyline(layer);
  
          var latlngs = layer.getLatLngs().slice(0),
              mindist = Infinity,
              result = null,
              i, n, distance;
  
          // Lookup vertices
          if (vertices) {
              for(i = 0, n = latlngs.length; i < n; i++) {
                  var ll = latlngs[i];
                  distance = L.GeometryUtil.distance(map, latlng, ll);
                  if (distance < mindist) {
                      mindist = distance;
                      result = ll;
                      result.distance = distance;
                  }
              }
              return result;
          }
  
          if (layer instanceof L.Polygon) {
              latlngs.push(latlngs[0]);
          }
  
          // Keep the closest point of all segments
          for (i = 0, n = latlngs.length; i < n-1; i++) {
              var latlngA = latlngs[i],
                  latlngB = latlngs[i+1];
              distance = L.GeometryUtil.distanceSegment(map, latlng, latlngA, latlngB);
              if (distance <= mindist) {
                  mindist = distance;
                  result = L.GeometryUtil.closestOnSegment(map, latlng, latlngA, latlngB);
                  result.distance = distance;
              }
          }
          return result;
      },
  
      /**
          Returns the closest layer to latlng among a list of layers.
          @param {L.Map} map
          @param {Array<L.ILayer>} layers
          @param {L.LatLng} latlng
          @returns {object} with layer, latlng and distance or {null} if list is empty;
      */
      closestLayer: function (map, layers, latlng) {
          var mindist = Infinity,
              result = null,
              ll = null,
              distance = Infinity;
  
          for (var i = 0, n = layers.length; i < n; i++) {
              var layer = layers[i];
              // Single dimension, snap on points, else snap on closest
              if (typeof layer.getLatLng == 'function') {
                  ll = layer.getLatLng();
                  distance = L.GeometryUtil.distance(map, latlng, ll);
              }
              else {
                  ll = L.GeometryUtil.closest(map, layer, latlng);
                  if (ll) distance = ll.distance;  // Can return null if layer has no points.
              }
              if (distance < mindist) {
                  mindist = distance;
                  result = {layer: layer, latlng: ll, distance: distance};
              }
          }
          return result;
      },
  
      /**
          Returns the closest position from specified {LatLng} among specified layers,
          with a maximum tolerance in pixels, providing snapping behaviour.
          @param {L.Map} map
          @param {Array<ILayer>} layers - A list of layers to snap on.
          @param {L.LatLng} latlng - The position to snap.
          @param {?Number} [tolerance=Infinity] - Maximum number of pixels.
          @param {?boolean} [withVertices=true] - Snap to layers vertices.
          @returns {object} with snapped {LatLng} and snapped {Layer} or null if tolerance exceeded.
      */
      closestLayerSnap: function (map, layers, latlng, tolerance, withVertices) {
          tolerance = typeof tolerance == 'number' ? tolerance : Infinity;
          withVertices = typeof withVertices == 'boolean' ? withVertices : true;
  
          var result = L.GeometryUtil.closestLayer(map, layers, latlng);
          if (!result || result.distance > tolerance)
              return null;
  
          // If snapped layer is linear, try to snap on vertices (extremities and middle points)
          if (withVertices && typeof result.layer.getLatLngs == 'function') {
              var closest = L.GeometryUtil.closest(map, result.layer, result.latlng, true);
              if (closest.distance < tolerance) {
                  result.latlng = closest;
                  result.distance = L.GeometryUtil.distance(map, closest, latlng);
              }
          }
          return result;
      },
  
      /**
          Returns the Point located on a segment at the specified ratio of the segment length.
          @param {L.Point} pA
          @param {L.Point} pB
          @param {Number} the length ratio, expressed as a decimal between 0 and 1, inclusive.
          @returns {L.Point} the interpolated point.
      */
      interpolateOnPointSegment: function (pA, pB, ratio) {
          return L.point(
              (pA.x * (1 - ratio)) + (ratio * pB.x),
              (pA.y * (1 - ratio)) + (ratio * pB.y)
          );
      },
  
      /**
          Returns the coordinate of the point located on a line at the specified ratio of the line length.
          @param {L.Map} map
          @param {Array<L.LatLng>|L.PolyLine} latlngs
          @param {Number} the length ratio, expressed as a decimal between 0 and 1, inclusive
          @returns {Object} an object with latLng ({LatLng}) and predecessor ({Number}), the index of the preceding vertex in the Polyline
          (-1 if the interpolated point is the first vertex)
      */
      interpolateOnLine: function (map, latLngs, ratio) {
          latLngs = (latLngs instanceof L.Polyline) ? latLngs.getLatLngs() : latLngs;
          var n = latLngs.length;
          if (n < 2) {
              return null;
          }
  
          if (ratio === 0) {
              return {latLng: latLngs[0],
                      predecessor: -1};
          }
          if (ratio == 1) {
              return {latLng: latLngs[latLngs.length -1],
                      predecessor: latLngs.length-2};
          }
  
          // ensure the ratio is between 0 and 1;
          ratio = Math.max(Math.min(ratio, 1), 0);
  
          // project the LatLngs as Points,
          // and compute total planar length of the line at max precision
          var maxzoom = map.getMaxZoom();
          if (maxzoom === Infinity)
              maxzoom = map.getZoom();
          var pts = [];
          var lineLength = 0;
          for(var i = 0; i < n; i++) {
              pts[i] = map.project(latLngs[i], maxzoom);
              if(i > 0)
                lineLength += pts[i-1].distanceTo(pts[i]);
          }
  
          var ratioDist = lineLength * ratio;
          var a = pts[0],
              b = pts[1],
              distA = 0,
              distB = a.distanceTo(b);
          // follow the line segments [ab], adding lengths,
          // until we find the segment where the points should lie on
          var index = 1;
          for (; index < n && distB < ratioDist; index++) {
              a = b;
              distA = distB;
              b = pts[index];
              distB += a.distanceTo(b);
          }
          // compute the ratio relative to the segment [ab]
          var segmentRatio = ((distB - distA) !== 0) ? ((ratioDist - distA) / (distB - distA)) : 0;
          var interpolatedPoint = L.GeometryUtil.interpolateOnPointSegment(a, b, segmentRatio);
          return {
              latLng: map.unproject(interpolatedPoint, maxzoom),
              predecessor: index-2
          };
      },
  
      /**
          Returns a float between 0 and 1 representing the location of the
          closest point on polyline to the given latlng, as a fraction of total 2d line length.
          (opposite of L.GeometryUtil.interpolateOnLine())
          @param {L.Map} map
          @param {L.PolyLine} polyline
          @param {L.LatLng} latlng
          @returns {Number}
      */
      locateOnLine: function (map, polyline, latlng) {
          var latlngs = polyline.getLatLngs();
          if (latlng.equals(latlngs[0]))
              return 0.0;
          if (latlng.equals(latlngs[latlngs.length-1]))
              return 1.0;
  
          var point = L.GeometryUtil.closest(map, polyline, latlng, false),
              lengths = L.GeometryUtil.accumulatedLengths(latlngs),
              total_length = lengths[lengths.length-1],
              portion = 0,
              found = false;
          for (var i=0, n = latlngs.length-1; i < n; i++) {
              var l1 = latlngs[i],
                  l2 = latlngs[i+1];
              portion = lengths[i];
              if (L.GeometryUtil.belongsSegment(point, l1, l2)) {
                  portion += l1.distanceTo(point);
                  found = true;
                  break;
              }
          }
          if (!found) {
              throw "Could not interpolate " + latlng.toString() + " within " + polyline.toString();
          }
          return portion / total_length;
      },
  
      /**
          Returns a clone with reversed coordinates.
          @param {L.PolyLine} polyline
          @returns {L.PolyLine}
      */
      reverse: function (polyline) {
          return L.polyline(polyline.getLatLngs().slice(0).reverse());
      },
  
      /**
          Returns a sub-part of the polyline, from start to end.
          If start is superior to end, returns extraction from inverted line.
          @param {L.Map} map
          @param {L.PolyLine} latlngs
          @param {Number} start ratio, expressed as a decimal between 0 and 1, inclusive
          @param {Number} end ratio, expressed as a decimal between 0 and 1, inclusive
          @returns {Array<L.LatLng>}
       */
      extract: function (map, polyline, start, end) {
          if (start > end) {
              return L.GeometryUtil.extract(map, L.GeometryUtil.reverse(polyline), 1.0-start, 1.0-end);
          }
  
          // Bound start and end to [0-1]
          start = Math.max(Math.min(start, 1), 0);
          end = Math.max(Math.min(end, 1), 0);
  
          var latlngs = polyline.getLatLngs(),
              startpoint = L.GeometryUtil.interpolateOnLine(map, polyline, start),
              endpoint = L.GeometryUtil.interpolateOnLine(map, polyline, end);
          // Return single point if start == end
          if (start == end) {
              var point = L.GeometryUtil.interpolateOnLine(map, polyline, end);
              return [point.latLng];
          }
          // Array.slice() works indexes at 0
          if (startpoint.predecessor == -1)
              startpoint.predecessor = 0;
          if (endpoint.predecessor == -1)
              endpoint.predecessor = 0;
          var result = latlngs.slice(startpoint.predecessor+1, endpoint.predecessor+1);
          result.unshift(startpoint.latLng);
          result.push(endpoint.latLng);
          return result;
      },
  
      /**
          Returns true if first polyline ends where other second starts.
          @param {L.PolyLine} polyline
          @param {L.PolyLine} other
          @returns {bool}
      */
      isBefore: function (polyline, other) {
          if (!other) return false;
          var lla = polyline.getLatLngs(),
              llb = other.getLatLngs();
          return (lla[lla.length-1]).equals(llb[0]);
      },
  
      /**
          Returns true if first polyline starts where second ends.
          @param {L.PolyLine} polyline
          @param {L.PolyLine} other
          @returns {bool}
      */
      isAfter: function (polyline, other) {
          if (!other) return false;
          var lla = polyline.getLatLngs(),
              llb = other.getLatLngs();
          return (lla[0]).equals(llb[llb.length-1]);
      },
  
      /**
          Returns true if first polyline starts where second ends or start.
          @param {L.PolyLine} polyline
          @param {L.PolyLine} other
          @returns {bool}
      */
      startsAtExtremity: function (polyline, other) {
          if (!other) return false;
          var lla = polyline.getLatLngs(),
              llb = other.getLatLngs(),
              start = lla[0];
          return start.equals(llb[0]) || start.equals(llb[llb.length-1]);
      },
  
      /**
          Returns horizontal angle in degres between two points.
          @param {L.Point} a
          @param {L.Point} b
          @returns {float}
       */
      computeAngle: function(a, b) {
          return (Math.atan2(b.y - a.y, b.x - a.x) * 180 / Math.PI);
      },
  
      /**
         Returns slope (Ax+B) between two points.
          @param {L.Point} a
          @param {L.Point} b
          @returns {Object} with ``a`` and ``b`` properties.
       */
      computeSlope: function(a, b) {
          var s = (b.y - a.y) / (b.x - a.x),
              o = a.y - (s * a.x);
          return {'a': s, 'b': o};
      },
      
      /**
         Returns LatLng of rotated point around specified LatLng center.
          @param {L.LatLng} latlngPoint: point to rotate
          @param {double} angleDeg: angle to rotate in degrees
          @param {L.LatLng} latlngCenter: center of rotation
          @returns {L.LatLng} rotated point
       */
      rotatePoint: function(map, latlngPoint, angleDeg, latlngCenter) {
          var maxzoom = map.getMaxZoom();
          if (maxzoom === Infinity)
              maxzoom = map.getZoom();
          var angleRad = angleDeg*Math.PI/180,
              pPoint = map.project(latlngPoint, maxzoom),
              pCenter = map.project(latlngCenter, maxzoom),
              x2 = Math.cos(angleRad)*(pPoint.x-pCenter.x) - Math.sin(angleRad)*(pPoint.y-pCenter.y) + pCenter.x,
              y2 = Math.sin(angleRad)*(pPoint.x-pCenter.x) + Math.cos(angleRad)*(pPoint.y-pCenter.y) + pCenter.y;
          return map.unproject(new L.Point(x2,y2), maxzoom);
      }
  });
  
  }());
  
  //Polyline
  
  L.EditDrag = L.EditDrag || {};
  
  L.EditDrag.Polyline = L.Handler.extend({
  
    options: {
      distance: 20,   //distance from pointer to the polyline
      tollerance: 5,  //tollerance for snap effect to vertex
      vertices: {
        //first: true,  //first vertex is draggable
        //middle: true, //middle vertices are draggables
        //last: true,   //last vertex draggable
        //insert: true, //define if the number of polyline's vertices can change
      },
      icon: new L.DivIcon({
        iconSize: new L.Point(8, 8),
        className: 'leaflet-div-icon leaflet-editing-icon'
      })
    },
  
    initialize: function(poly) {
      this._poly = poly;
      this._marker = null;
      this._dragging = false;
      L.Util.setOptions(this, poly.options);
    },
  
    addHooks: function() {
      if (this._poly._map) {
        this._map = this._poly._map;
        this._map.on('mousemove', this._mouseMove, this);
      }
    },
  
    removeHooks: function() {
      this._map.off('mousemove');
    },
  
    /**
    * return the closest point on the closest segment
    */
    _getClosestPointAndSegment: function(latlng) {
      var distanceMin = Infinity;
      var segmentMin = null;
  
      for (var i = 0, len = (this._poly._latlngs.length - 1); i < len; i++) {
        var segment = [ this._poly._latlngs[i], this._poly._latlngs[i + 1] ];
        var distance = L.GeometryUtil.distanceSegment(this._map, latlng, segment[0], segment[1]);
        if (distance < distanceMin) {
          distanceMin = distance;
          segmentMin = segment;
        }
      }
  
      return { point: L.GeometryUtil.closestOnSegment(this._map, latlng, segmentMin[0], segmentMin[1]) , segment: segmentMin };
    },
  
    _mouseContextClick: function(e) {
      var closest = L.GeometryUtil.closest(this._map, this._poly, e.latlng, true);
  
      if (this.options.vertices.destroy !== false && closest.distance < this.options.tollerance) {
        var index = this._poly._latlngs.indexOf(closest);
        var maxIndex = (this._poly._latlngs.length - 1);
        if ((this.options.vertices.first === false && index == 0) || (this.options.vertices.last === false && index == maxIndex)) {
          return;
        }
        this._poly.spliceLatLngs(index, 1);
        this._map.removeLayer(this._marker);
        this._marker = null;
      }
    },
  
    _mouseMove: function(e) {
      if (this._dragging) return;
  
      var closest = L.GeometryUtil.closestLayerSnap(this._map, [this._poly], e.latlng, this.options.distance, false);
  
      if (this._marker && closest) {
        this._marker.addTo(this._map);
        L.extend(this._marker._latlng, closest.latlng);
        this._marker.options.draggable = true;
        this._marker.update();
  
      } else if (!this._marker && closest) {
        this._marker = L.marker(closest.latlng, { draggable: true, icon: this.options.icon }).addTo(this._map);
        this._marker.on('dragstart', this._markerDragStart, this);
        this._marker.on('drag', this._markerDrag, this);
        this._marker.on('dragend', this._markerDragEnd, this);
        this._marker.on('contextmenu', this._mouseContextClick, this);
  
      } else if (this._marker) {
        this._map.removeLayer(this._marker);
        this._marker = null;
      }
    },
  
    _isInvalidDrag: function(index) {
      var maxIndex = (this._poly._latlngs.length - 1);
  
      if ((this.options.vertices.first === false && index == 0) ||
          (this.options.vertices.last === false && index == maxIndex) ||
          (this.options.vertices.middle === false && (index > 0 && index < maxIndex))) {
        return true;
      }
  
      if ((this.options.vertices.middle === false || this.options.vertices.insert === false) && index === -1) {
        return true;
      }
  
      return false;
    },
  
    _markerDragStart: function(e) {
      var latlng = e.target.getLatLng();
  
      this.closest = L.GeometryUtil.closest(this._map, this._poly, latlng, true);
      this._dragging = true;
      //check the tollerance
      if (this.closest.distance < this.options.tollerance) {
        var index = this._poly._latlngs.indexOf(this.closest);
  
        if (this._isInvalidDrag(index)) {
          this.closest = null;
          this._marker.options.draggable = false;
        }
  
      } else {
        this.closest = this._getClosestPointAndSegment(latlng);
        var index = this._poly._latlngs.indexOf(this.closest);
  
        if (this._isInvalidDrag(index)) {
          this.closest = null;
          this._marker.options.draggable = false;
          return;
        }
  
        //add a new vertex
        var insertAt = this._poly._latlngs.indexOf(this.closest.segment[1]);
        this._poly._latlngs.splice(insertAt, 0, this.closest);
      }
    },
  
    _markerDrag: function(e) {
      if (this.closest) {
        this.closest.lat = e.target.getLatLng().lat;
        this.closest.lng = e.target.getLatLng().lng;
        this._poly.redraw();
      }
    },
  
    _markerDragEnd: function(e) {
      this._dragging = false;
    }
  });
  
  L.Polyline.addInitHook(function() {
  
    if (this.edit_with_drag) {
      return;
    }
  
    if (L.EditDrag.Polyline) {
      this.editingDrag = new L.EditDrag.Polyline(this);
  
      if (this.options.edit_with_drag) {
        this.editingDrag.enable();
      }
    }
  
    this.on('add', function () {
      if (this.editingDrag && this.editingDrag.enabled()) {
        this.editingDrag.addHooks();
      }
    });
  
    this.on('remove', function () {
      if (this.editingDrag && this.editingDrag.enabled()) {
        this.editingDrag.removeHooks();
      }
    });
  });

  L.Polyline.include({

	// Hi-res timestamp indicating when the last calculations for vertices and
	// distance took place.
	_snakingTimestamp: 0,

	// How many rings and vertices we've already visited
	// Yeah, yeah, "rings" semantically only apply to polygons, but L.Polyline
	// internally uses that nomenclature.
	_snakingRings: 0,
	_snakingVertices: 0,

	// Distance to draw (in screen pixels) since the last vertex
	_snakingDistance: 0,

	// Flag
	_snaking: false,


	/// TODO: accept a 'map' parameter, fall back to addTo() in case
	/// performance.now is not available.
	snakeIn: function(){

		if (this._snaking) { return; }

		if ( !('performance' in window) ||
		     !('now' in window.performance) ||
		     !this._map) {
			return;
		}

		this._snaking = true;
		this._snakingTime = performance.now();
		this._snakingVertices = this._snakingRings = this._snakingDistance = 0;

		if (!this._snakeLatLngs) {
			this._snakeLatLngs = L.LineUtil.isFlat(this._latlngs) ?
				[ this._latlngs ] :
				this._latlngs ;
		}

		// Init with just the first (0th) vertex in a new ring
		// Twice because the first thing that this._snake is is chop the head.
		this._latlngs = [[ this._snakeLatLngs[0][0], this._snakeLatLngs[0][0] ]];

		this._update();
		this._snake();
		this.fire('snakestart');
		return this;
	},


	_snake: function(){

		var now = performance.now();
		var diff = now - this._snakingTime;	// In milliseconds
		var forward = diff * this.options.snakingSpeed / 1000;	// In pixels
		this._snakingTime = now;

		// Chop the head from the previous frame
		this._latlngs[ this._snakingRings ].pop();

		return this._snakeForward(forward);
	},

	_snakeForward: function(forward) {

		// If polyline has been removed from the map stop _snakeForward
		if (!this._map) return;
		// Calculate distance from current vertex to next vertex
		var currPoint = this._map.latLngToContainerPoint(
			this._snakeLatLngs[ this._snakingRings ][ this._snakingVertices ]);
		var nextPoint = this._map.latLngToContainerPoint(
			this._snakeLatLngs[ this._snakingRings ][ this._snakingVertices + 1 ]);

		var distance = currPoint.distanceTo(nextPoint);

// 		console.log('Distance to next point:', distance, '; Now at: ', this._snakingDistance, '; Must travel forward:', forward);
// 		console.log('Vertices: ', this._latlngs);

		if (this._snakingDistance + forward > distance) {
			// Jump to next vertex
			this._snakingVertices++;
			this._latlngs[ this._snakingRings ].push( this._snakeLatLngs[ this._snakingRings ][ this._snakingVertices ] );

			if (this._snakingVertices >= this._snakeLatLngs[ this._snakingRings ].length - 1 ) {
				if (this._snakingRings >= this._snakeLatLngs.length - 1 ) {
					return this._snakeEnd();
				} else {
					this._snakingVertices = 0;
					this._snakingRings++;
					this._latlngs[ this._snakingRings ] = [
						this._snakeLatLngs[ this._snakingRings ][ this._snakingVertices ]
					];
				}
			}

			this._snakingDistance -= distance;
			return this._snakeForward(forward);
		}

		this._snakingDistance += forward;

		var percent = this._snakingDistance / distance;

		var headPoint = nextPoint.multiplyBy(percent).add(
			currPoint.multiplyBy( 1 - percent )
		);

		// Put a new head in place.
		var headLatLng = this._map.containerPointToLatLng(headPoint);
		this._latlngs[ this._snakingRings ].push(headLatLng);

		this.setLatLngs(this._latlngs);
		this.fire('snake');
		L.Util.requestAnimFrame(this._snake, this);
	},

	_snakeEnd: function() {

		this.setLatLngs(this._snakeLatLngs);
		this._snaking = false;
		this.fire('snakeend');

	}

});


L.Polyline.mergeOptions({
	snakingSpeed: 200	// In pixels/sec
});





L.LayerGroup.include({

	_snakingLayers: [],
	_snakingLayersDone: 0,

	snakeIn: function() {

		if ( !('performance' in window) ||
		     !('now' in window.performance) ||
		     !this._map ||
		     this._snaking) {
			return;
		}


		this._snaking = true;
		this._snakingLayers = [];
		this._snakingLayersDone = 0;
		var keys = Object.keys(this._layers);
		for (var i in keys) {
			var key = keys[i];
			this._snakingLayers.push(this._layers[key]);
		}
		this.clearLayers();

		this.fire('snakestart');
		return this._snakeNext();
	},


	_snakeNext: function() {


		if (this._snakingLayersDone >= this._snakingLayers.length) {
			this.fire('snakeend');
			this._snaking = false;
			return;
		}

		var currentLayer = this._snakingLayers[this._snakingLayersDone];

		this._snakingLayersDone++;

		this.addLayer(currentLayer);
		if ('snakeIn' in currentLayer) {
			currentLayer.once('snakeend', function(){
				setTimeout(this._snakeNext.bind(this), this.options.snakingPause);
			}, this);
			currentLayer.snakeIn();
		} else {
			setTimeout(this._snakeNext.bind(this), this.options.snakingPause);
		}


		this.fire('snake');
		return this;
	}

});


L.LayerGroup.mergeOptions({
	snakingPause: 200
});

