import { Injectable } from '@angular/core';
import { MenuNavService } from '../services/menu-navigation.service';

@Injectable()
export class RoleService {
  private roles: any = {
    1: {
      role: 'Administrator',
      defaultRoute: '/dashboard'
    },
    2: {
      role: 'Accounts',
      defaultRoute: '/invoice'
    },
    3: {
      role: 'Technician',
      defaultRoute: '/jobsheet'
    },
    4: {
      role: 'Installers',
      defaultRoute: '/jobsheet'
    },
    5: {
      role: 'Maintenance',
      defaultRoute: '/jobsheet'
    }
  };

  constructor(
    private menuService : MenuNavService
  ) { }

  getDefaultRoute(role: number): string{
    // //return "/user";
    // let route = this.menuService.menuItems[0].routerLink ? this.menuService.menuItems[0].routerLink : this.menuService.menuItems[0].submenu[0].routerLink;
    // return route;
    // //return this.roles[role].defaultRoute;
    return "/session/login";
  }
}
