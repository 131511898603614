import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserModel } from "./user.model";

export class DeviceModel {

    id: number;
    camino_user:UserModel;
    device_id: string;
    token: string;
    platform: string;
    os_version: string;
    app_version: string;

    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.camino_user = input.camino_user || '';
        this.device_id = input.device_id || '';
        this.token = input.token || '';
        this.platform = input.platform || '';
        this.os_version = input.os_version || '';
        this.app_version = input.app_version || '';
    }

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();
        return fb.group({
            device_id: [input.device_id],
            token: [input.token],
            camino_user: [input.camino_user],
            platform: [input.platform],
            os_version: [input.os_version],
            app_version: [input.app_version]
        })
    }

    static getListRow(row: DeviceModel) {
        return {
            id: row.id,
            camino_user:row.camino_user,
            platform: row.platform,
            device_id: row.device_id,
        };
    }

}