import { Routes } from '@angular/router';
import { AuthGaurd } from '../../../shared/guards/auth-guard.service';
import { RoleGuard } from '../../../shared/guards/role-guard';
import { GroupDetailComponent } from './groups-detail/groups-detail.component';
import { GroupListComponent } from './groups-list/groups-list.component';

export const WorksCategoryRoutes: Routes = [
  {
    path: '',
    component: GroupListComponent
  },
  {
    path: 'new',
    canActivate: [AuthGaurd,RoleGuard],
    component: GroupDetailComponent
  },
  {
    path: ':id',
    canActivate: [AuthGaurd,RoleGuard],
    component: GroupDetailComponent
  },
];
