import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Location } from '@angular/common';

@Component({
  selector: 'main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent {

  @Input() module:any;
  @Input() menuItems:any;
  @Input() handleButtonPress:any;
  @Output() submitEvent:EventEmitter<any> = new EventEmitter<any>();
  @Input() isDetail:boolean = false;
  @Input() isSaved:boolean = false;
  @Input() external:boolean = false;
  @Input() hideCreate:boolean = false;

  constructor(
    private permissionService : NgxPermissionsService,
    private router : Router,
    private _location: Location,
  ) { }
  
  create() {
    let _this = this;
    this.router.navigate([this.module.new_name+'/new']);
  }

  sendBack() {
    let _this = this;
    this.router.navigate([this.module.new_name+'']);
  }

}