import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Utils } from "../services/utils.service";
import { GroupModel } from "./permission-group.model";

export class UserModel {

  address_1: string;
  address_2: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  phone: string;
  post_code: string;
  groups:GroupModel[];
  photo:string;
  date_of_birth:string;

  constructor(input?) {
    input = input || {};
    this.id = input.id || '';
    this.first_name = input.first_name || '';
    this.last_name = input.last_name || '';
    this.email = input.email || '';
    this.post_code = input.post_code || '';
    this.phone = input.phone || '';
    this.address_2 = input.address_2 || '';
    this.address_1 = input.address_1 || '';
    this.groups = input.groups || [];
    this.photo = input.photo || '';
    this.date_of_birth = input.date_of_birth || '';
  }

  static getFormGroup(input): FormGroup {
    let fb: FormBuilder = new FormBuilder();
    return fb.group({
      first_name: [input.first_name, Validators.required],
      last_name: [input.last_name, Validators.required],
      email: [{ value: input.email, disabled: input.email }, Validators.required],
      password: [input.password],
      password_confirmation: [input.password_confirmation],
      address_1: [input.address_1, Validators.required],
      address_2: [input.address_2],
      post_code: [input.post_code],
      phone: [input.phone],
      groups:[input.groups, Validators.required],
      photo:[input.photo],
      date_of_birth:[input.date_of_birth]
    })
  }

  static getListRow(row:UserModel) {
    return {
      id: row.id,
      name: row.first_name + ' ' + row.last_name,
      address: Utils.getFormattedAddress(row),
      email: row.email
    };
  }

}