import { FormBuilder, FormGroup, Validators } from "@angular/forms";

export class StaticPageModel {

    id: number;
    title:string;
    content:string;

    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.title = input.title || '';
        this.content = input.content || '';
    }

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();
        return fb.group({
            title: [input.title,Validators.required],
            content: [input.content,Validators.required],
        })
    }

    static getListRow(row: StaticPageModel) {
        return {
            id: row.id,
            title:row.title
        };
    }

}