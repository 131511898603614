import { EventEmitter } from "@angular/core";
import { EmployeeModel } from "../../shared/models/employee.model";
import { ColumnModel } from "../../shared/models/column.model";

export class EmployeeBuildData{
    module = {
        name: "Employee",
        new_name: "employee",
        has_dot_menu: true,
        icon:'fa-user',
        permission:'employee'
    }
    
    onReload: EventEmitter < boolean > = new EventEmitter<boolean>();//Forces Reload of codeblack table on emit.
    rowFunction = (row:EmployeeModel) => {//Generates Row for codeblack-table
        return EmployeeModel.getListRow(row);
    }
    
    columns:ColumnModel[] = [
        {
            title: "Name",
            prop: "first_name",
            flex_grow: 2,
            row_accessor: "name",
            class:""//force-flex force-flex-end
        },
        {
            title: "Address",
            prop: "address_1",
            flex_grow: 2,
            row_accessor: "address"
        },
        {
            title: "Phone",
            prop: "phone_number",
            row_accessor: "phone"
        },
        {
            title: "Email",
            prop: "email",
            flex_grow: 2,
            row_accessor: "email"
        },
    ];

    constructor(){
        for(let col of this.columns){
            col = new ColumnModel(col);
        }
    }

}