import { EventEmitter } from "@angular/core";
import { CaminoModel } from "../../shared/models/camino.model";
import { ColumnModel } from "../../shared/models/column.model";

export class CaminoBuildData{
    module = {
        name: "Camino",
        new_name: "camino",
        has_dot_menu: true,
        icon:'fa-street-view',
        permission:'camino'
    }
    
    onReload: EventEmitter < boolean > = new EventEmitter<boolean>();//Forces Reload of codeblack table on emit.
    rowFunction = (row:CaminoModel) => {//Generates Row for codeblack-table
        return CaminoModel.getListRow(row);
    }
    
    columns:ColumnModel[] = [
        {
            title: "Id",
            prop: "id",
            flex_grow: 1,
            row_accessor: "id",
            class:""//force-flex force-flex-end
        },
        {
            title: "Route",
            prop: "route",
            flex_grow: 2,
            row_accessor: "route",
        },
        // {
        //     title: "Device Id",
        //     prop: "device_id",
        //     flex_grow: 3,
        //     row_accessor: "device_id",
        // },
		{
            title: "User",
            prop: "user_fullname",
            flex_grow: 2,
            row_accessor: "user_fullname",
        },
        {
            title: "Start Date",
            prop: "start_at",
            flex_grow: 2,
            row_accessor: "start_at",
        },
        {
            title: "Completed",
            prop: "is_completed",
            flex_grow: 1,
            row_accessor: "is_completed",
            html_data:true
        }
    ];

    constructor(){
        for(let col of this.columns){
            col = new ColumnModel(col);
        }
    }

}