import { Routes } from '@angular/router';
import { AuthGaurd } from '../../shared/guards/auth-guard.service';
import { RoleGuard } from '../../shared/guards/role-guard';
import { RouteDetailComponent } from './route-detail/route-detail.component';
import { RouteListComponent } from './route-list/route-list.component';

export const RouteRoutes: Routes = [
  {
    path: '',
    component: RouteListComponent
  },
  {
    path: 'new',
    canActivate: [AuthGaurd,RoleGuard],
    component: RouteDetailComponent
  },
  {
    path: ':id',
    canActivate: [AuthGaurd,RoleGuard],
    component: RouteDetailComponent
  },
];
