import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Utils } from '../../../shared/services/utils.service';
import { AbstractControl } from '@angular/forms';
import * as $ from 'jquery';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
  selector: 'cb-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  @Input() canEdit:boolean = true;
  @Input() control: string;
  @Input() title: string;
  @Input() type:string = "text";
  @Input() is_required: boolean = false;
  @Input() form: FormGroup;
  @Input() prefix:string;
  @Output() onValueChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Input() triggerFetch: EventEmitter<FormGroup>;
  @ViewChild('input',{static:false}) inputComponent : ElementRef;
  value: any;

  constructor(
    private fb: FormBuilder,
    private utils: Utils
  ) { }

  ngOnInit() {
    let _this = this;
    if (_this.form && _this.triggerFetch) {
      _this.triggerFetch.subscribe(res => {
        let newValue = res.get(_this.control).value;
        //console.log("New Input Value: "+newValue);
        _this.value = newValue;
      })
    }
  }

  isTextArea(){
    return this.type == 'textarea';
  }

  euroPrefix = createNumberMask({
    prefix: '€ ',
    suffix: '',
    allowDecimal: true
  });

  focusComponent(){
    this.inputComponent.nativeElement.focus();
  }

  onChange(newVal) {
    let _this = this;
    if(this.prefix){
      newVal = +newVal.toString().replace(/[^0-9\.]+/g, "");
    }
    _this.form.get(_this.control).setValue(newVal);
  }

  setTouched() {
    this.form.get(this.control).markAsTouched();
    this.form.get(this.control).updateValueAndValidity();
  }

  isTouched() {
    return Utils.isFormTouched(this.form, this.control);
  }

  isControlRequired = (): boolean => {
    try {
      const validator = this.form.get(this.control).validator({} as AbstractControl);
      //console.log(this.control + " = " + validator.required);
      if (validator && validator.required) {
        return true;
      }
    }
    catch (Err) {

    }
    return false;
  };

}