import { Routes } from '@angular/router';
import { AuthGaurd } from '../../shared/guards/auth-guard.service';
import { RoleGuard } from '../../shared/guards/role-guard';
import { CaminoDetailComponent } from './camino-detail/camino-detail.component';
import { CaminoListComponent } from './camino-list/camino-list.component';

export const CaminoRoutes: Routes = [
  {
    path: '',
    component: CaminoListComponent
  },
  {
    path: 'new',
    canActivate: [AuthGaurd,RoleGuard],
    component: CaminoDetailComponent
  },
  {
    path: ':id',
    canActivate: [AuthGaurd,RoleGuard],
    component: CaminoDetailComponent
  },
];
