
import { Component, OnInit, Input, ViewEncapsulation, HostListener, ChangeDetectorRef } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'pg-menu-items',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('toggleHeight', [
      state('close', style({
        height: '0',
        overflow: 'hidden',
        marginBottom: '0',
        display: 'none',

      })),
      state('open', style({
        display: 'block',
        height: '*',
        marginBottom: '10px',
        // overflow: 'hidden',
      })),
      transition('close => open', animate('140ms ease-in')),
      transition('open => close', animate('140ms ease-out'))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {
  menuItems = [];
  currentItem = null;
  isPerfectScrollbarDisabled = false
  public config: PerfectScrollbarConfigInterface = {};
  constructor(
    private authService: AuthService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    for (let i of this.menuItems) {
      i.toggle = 'close';
    }
    //console.log(this.menuItems);
  }

  hasPermission(perm){
    return this.authService.hasPermission(perm);
  }

  isActive(url, exact?) {
    let routerUrl = this.router.url.toLowerCase();

    let split: any[] = routerUrl.split("/");
    if (split.length > 2) {
      if (!isNaN(split[2]) || split[2] == 'new') {
        routerUrl = "/" + split[1];
      }
      //routerUrl = routerUrl.split("/")[0];
    }
    if (split.length > 3) {
      if (!isNaN(split[3]) || split[3] == 'new') {
        routerUrl = "/" + split[1]+'/'+split[2];
      }
      //routerUrl = routerUrl.split("/")[0];
    }

    routerUrl = routerUrl.split("?")[0];
    url = url.split("?")[0];

    // console.log(routerUrl);
    // console.log(url);

    try {
      if (!exact) {
        if (routerUrl.includes(url)) {
          return true;
        }
      }
      else {
        if (routerUrl.startsWith("/")) {
          routerUrl = routerUrl.substring(1, routerUrl.length);
        }
        if (url.startsWith("/")) {
          url = url.substring(1, url.length);
        }
        let isExact = routerUrl == url.toLowerCase();
        if (isExact) {
          //console.log("Found Exact Match for Route: "+url);
          return true;
        }
      }
    }
    catch (err) {

    }
    return false;
  }

  count = 3;
  isOpen(item): boolean {
    if (item["toggle"] == 'close') {
      return false;
    }
    return true;
  }

  childIsActive(item) {
    if (item.submenu) {
      for (let menuItem of item.submenu) {
        let routerUrl = this.router.url.toLowerCase();
        routerUrl = routerUrl.split("?")[0];
        let url = menuItem.routerLink.split("?")[0];
        // if (url == "/invoice" && routerUrl.includes("receipt")) {
        //   return true;
        // }
        if (this.isActive(menuItem.routerLink, true)) {
          return true;
        }
      }
    }
    return false;
  }

  openLink(item) {
    let _this = this;
    // if (optItem) {
    //   optItem['toggle'] = optItem['toggle'] == 'open' ? 'close' : 'open';
    // }
    // if (item == undefined || item == '/undefined') {
    //   return;
    // }
    // if (item.charAt(0) != '/') {
    //   item = "/" + item;
    // }
    // this.router.navigate([item]);
    // if(item['submenu']){
    //   item['toggle'] = 'open';
    //   setInterval(() => {
    //     console.log(item);
    //     try{
    //       _this.cdr.detectChanges();
    //     }
    //     catch(err){

    //     }
    //   },1000);
    // }
  }

  logout() {
    this.authService.signout();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.togglePerfectScrollbar();
    })
  }

  @HostListener("window:resize", [])
  onResize() {
    this.togglePerfectScrollbar();
  }

  togglePerfectScrollbar() {
    this.isPerfectScrollbarDisabled = window.innerWidth < 1025
  }

  @Input()
  set Items(value) {
    this.menuItems = value
  }

  switch(item) {
    if (this.menuItems[item].toggle !== 'open') {
      this.menuItems[item].toggle = 'open';
    }
    else {
      this.menuItems[item].toggle = 'close';
    }
  }

  toggleNavigationSub(event, item) {
    event.preventDefault();
    if (this.currentItem && this.currentItem != item) {
      this.currentItem["toggle"] = 'close';
    }
    this.currentItem = item;
    item.toggle = (item.toggle === 'close' ? 'open' : 'close');
  }
}
