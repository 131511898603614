import { Component, OnInit, ViewChild } from '@angular/core';
import { EChartOption } from 'echarts';
import * as moment from 'moment';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { isDate } from 'util';
import { AuthService } from '../../../shared/services/auth.service';
import { DashboardService } from '../../../shared/services/dashboard.service';
import { Utils } from '../../../shared/services/utils.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class Dashboard implements OnInit {

    periodsMap: Map<number, any> = new Map<number, any>();
    periods: any[] = [{ id: 1, title: "Last 24 Hours" }, { id: 2, title: "Last Week" }, { id: 3, title: "Last Month" }, { id: 4, title: 'Custom Range' }];
    activePeriod: number = 1;
    timeout: any;

    constructor(
        private dashboardService: DashboardService,
        private utilsService: Utils
    ) {
    }

    startInput: Date;
    endInput: Date;

    setDateRange(event) {
        let dates: Date[] = event;
        this.startInput = dates[0];
        this.endInput = dates[1];
        this.updateFilters();
    }

    updateFilters() {
        switch (this.activePeriod) {
            case 1:
                this.startInput = moment(new Date()).subtract(1, 'd').toDate();
                this.endInput = new Date();
                break;
            case 2:
                this.startInput = moment(new Date()).add(-7, "days").startOf('day').toDate();
                this.endInput = new Date();
                break;
            case 3:
                this.startInput = moment(new Date()).add(-30, "days").startOf('day').toDate();
                this.endInput = new Date();
                break;
        }
        this.loadByDate();
    }

    isLoading: boolean = false;

    ngOnInit() {
        let _this = this;
        this.updateFilters();
        for (let v of this.periods) {
            this.periodsMap.set(v.id, v.title);
        }
    }

    loadByDate() {
        let _this = this;
        if (isDate(this.startInput) && isDate(this.endInput)) {
            _this.loadCharts();
        }
    }

    employeePieHours: any;
    projectPieHours: any;
    worksPieHours: any;
    projectBarHours: any;
    employeeBarHours: any;

    loadCharts() {
        let _this = this;
        let pieEmployee = () => {
            let formattedData = {
                names: [],
                data: []
            };
            _this.dashboardService.getPieEmployee({ date_from: moment(_this.startInput).format(), date_to: moment(_this.endInput).format() }).subscribe(res => {
                _this.employeePieHours = Utils.getPieChart();
                for (let r of res['data']) {
                    formattedData.names.push(r['employee']);
                    formattedData.data.push({
                        value: (r['hours_worked']),
                        name: r['employee']
                    });
                }
                _this.employeePieHours['legend']['data'] = formattedData.names;
                _this.employeePieHours['series'][0]['data'] = formattedData.data;
            },
                err => {
                    console.log(err);
                })
        }

        let pieProject = () => {
            let formattedData = {
                names: [],
                data: []
            };
            _this.dashboardService.getPieProject({ date_from: moment(_this.startInput).format(), date_to: moment(_this.endInput).format() }).subscribe(res => {
                _this.projectPieHours = Utils.getPieChart();
                for (let r of res['data']) {
                    formattedData.names.push(r['project']);
                    formattedData.data.push({
                        value: (r['hours_worked']),
                        name: r['project']
                    });
                }
                _this.projectPieHours['legend']['data'] = formattedData.names;
                _this.projectPieHours['series'][0]['data'] = formattedData.data;
            },
                err => {
                    console.log(err);
                })
        }

        let pieWorks = () => {
            let formattedData = {
                names: [],
                data: []
            };
            _this.dashboardService.getPieWorks({ date_from: moment(_this.startInput).format(), date_to: moment(_this.endInput).format() }).subscribe(res => {
                _this.worksPieHours = Utils.getPieChart();
                for (let r of res['data']) {
                    formattedData.names.push(r['works']);
                    formattedData.data.push({
                        value: (r['hours_worked']),
                        name: r['works']
                    });
                }
                _this.worksPieHours['legend']['data'] = formattedData.names;
                _this.worksPieHours['series'][0]['data'] = formattedData.data;
            },
                err => {
                    console.log(err);
                })
        }

        let barProject = () => {
            _this.dashboardService.getBarProject({ date_from: moment(_this.startInput).format(), date_to: moment(_this.endInput).format() }).subscribe(res => {
                _this.projectBarHours = Utils.getBarChart();

                let seriesMap: Map<string, any> = new Map<string, any>();
                let dates = [];

                let seriesIndex = 0;

                let hoursArraySkeleton = [];
                for (let x in res['data']) {
                    hoursArraySkeleton.push(0);
                    let stackData = res['data'][x];
                    let date = moment(stackData['date']).format('DD/MM/YYYY');
                    dates.push(date);
                }

                for (let stack in res['data']) {
                    let stackData = res['data'][stack];
                    for (let projectRecord of stackData['data']) {
                        let projectName = projectRecord['project'];
                        let hoursWorked = projectRecord['hours_worked'];
                        if (seriesMap.has(projectName)) {
                            let curr = seriesMap.get(projectName);
                            curr[seriesIndex] = hoursWorked
                            seriesMap.set(projectName, curr);
                        }
                        else {
                            let tmp = [...hoursArraySkeleton];
                            tmp[seriesIndex] = hoursWorked;
                            seriesMap.set(projectName, tmp);
                        }
                    }
                    seriesIndex++;
                }

                let index = 1;
                let seriesArr = [];
                seriesMap.forEach((val, key) => {
                    let series = {
                        symbolSize: 10,
                        name: key,
                        type: 'bar',
                        stack: 1,
                        areaStyle: {},
                        z: index,
                        barGap: "-100%",
                        barPadding: 2,
                        data: val
                    };
                    seriesArr.push(series);
                    index++;
                });

                _this.projectBarHours['series'] = seriesArr;
                _this.projectBarHours['legend']['data'] = Array.from(seriesMap.keys());
                _this.projectBarHours['xAxis'][0]['data'] = dates;
            },
                err => {
                    console.log(err);
                })
        }

        let barEmployee = () => {
            _this.dashboardService.getBarEmployees({ date_from: moment(_this.startInput).format(), date_to: moment(_this.endInput).format() }).subscribe(res => {
                _this.employeeBarHours = Utils.getBarChart();

                let seriesMap: Map<string, any> = new Map<string, any>();
                let names = [];

                let seriesIndex = 0;

                for (let stack of res['data']) {
                    names.push(stack['employee']);
                    console.log(stack);
                    for (let hoursRecord in stack['hours']) {
                        let hoursTypes = hoursRecord.split("_");
                        let formattedTypes = [];
                        
                        for(let hour of hoursTypes){
                            let first = hour.substr(0,1).toUpperCase();
                            hour = first + hour.substr(1);
                            formattedTypes.push(hour);
                        } 

                        let hoursName = formattedTypes.join(" ").replace("Total ","");

                        //.join(" ").replace("total ","");

                        console.log(stack['hours'][hoursRecord]);
                        let hoursWorked = stack['hours'][hoursRecord];
                        if (seriesMap.has(hoursName)) {
                            let curr = seriesMap.get(hoursName);
                            curr[seriesIndex] = hoursWorked
                            seriesMap.set(hoursName, curr);
                        }
                        else {
                            let tmp = [];
                            tmp[seriesIndex] = hoursWorked;
                            seriesMap.set(hoursName, tmp);
                        }
                    }
                    seriesIndex++;
                }

                let index = 1;
                let seriesArr = [];
                seriesMap.forEach((val, key) => {
                    let series = {
                        symbolSize: 10,
                        name: key,
                        type: 'bar',
                        stack: 1,
                        areaStyle: {},
                        z: index,
                        barGap: "-100%",
                        barPadding: 2,
                        data: val
                    };
                    seriesArr.push(series);
                    index++;
                });

                _this.employeeBarHours['series'] = seriesArr;
                _this.employeeBarHours['legend']['data'] = Array.from(seriesMap.keys());
                _this.employeeBarHours['xAxis'][0]['data'] = names;
            },
                err => {
                    console.log(err);
                })
        }

        barEmployee();
        barProject();
        pieWorks();
        pieProject();
        pieEmployee();
    }

    getPeriodName(): string {
        return Utils.getFormattedPeriod(this.startInput, this.endInput);
    }


    isToday(date) {
        if (moment(date).isSame(new Date(), 'date')) {
            return true;
        } else {
            return false;
        }
    }



}
