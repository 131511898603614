import { Routes } from '@angular/router';
import { AuthGaurd } from '../../shared/guards/auth-guard.service';
import { RoleGuard } from '../../shared/guards/role-guard';
import { StaticPageDetailComponent } from './static-pages-detail/static-pages-detail.component';
import { StaticPageListComponent } from './static-pages-list/static-pages-list.component';

export const StaticPageRoutes: Routes = [
  {
    path: '',
    component: StaticPageListComponent
  },
  {
    path: 'new',
    canActivate: [AuthGaurd,RoleGuard],
    component: StaticPageDetailComponent
  },
  {
    path: ':id',
    canActivate: [AuthGaurd,RoleGuard],
    component: StaticPageDetailComponent
  },
];
