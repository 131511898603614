import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef, ViewChildren } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import * as $ from 'jquery';
import * as L from 'leaflet';
import { Utils } from '../../../shared/services/utils.service';
import { DeviceBuildData } from '../route.build-data';
import { pgDatePickerComponent } from '../../../@pages/components/datepicker/datepicker.component';
import * as moment from 'moment';
import { PermissionService } from '../../../shared/services/permission.service';
import { PermissionGroupService } from '../../../shared/services/permission-group.service';
import { PaginatedSelectComponent } from '../../components/paginated-select/paginated-select.component';
import { DeviceService } from '../../../shared/services/device.service';
import { RouteService } from '../../../shared/services/route.service';
import { PointOfInterestModel, RouteModel } from '../../../shared/models/route.model';
import * as FullL from '../polyline.data';
import * as Draw from 'leaflet-draw';
import { POITypeService } from '../../../shared/services/poi-type.service';
import { AdvertService } from '../../../shared/services/advert.service';
import { isArray } from 'rxjs/internal-compatibility';
import { PolylineFeatures } from './route.polyline';
import { pgUploadComponent } from '../../../@pages/components/upload/upload.component';
import { PilgrimGuideService } from '../../../shared/services/pilgrim-guide.service';
import { Observable, Subscription } from 'rxjs';
import { invalid } from '@angular/compiler/src/render3/view/util';
//import * as GeneticJS from 'genetic-js';

@Component({
	selector: 'app-route-detail',
	templateUrl: './route-detail.component.html',
	styleUrls: ['./route-detail.component.scss']
})
export class RouteDetailComponent implements OnInit {

	//Route Section

	activePolyline: any;
	defaultColor: string = '#b2ebf2';
	userColor: any = '#26a69a';
	isRouteDeleteMode = false;
	isPointOfInterestMode = false;

	//time: string='';


	canDrag() {
		if (this.external) {
			return false;
		}
		return true;
	}

	canDragRoute() {
		if (this.external || !this.isRouteEditMode) {
			return false;
		}
		return true;
	}

	switchMode(mode) {

		if (this.isRouteDeleteMode) {
			this.cancelDelete();
		}

		this.isRouteMode = false;
		this.isRouteDeleteMode = false;
		this.isPointOfInterestMode = false;
		this.isRouteEditMode = false;
		this.mapview = true;

		if (mode == 'add_route') {
			this.isRouteMode = true;
		}
		else if (mode == 'delete_route') {
			this.isRouteDeleteMode = true;
		}
		else if (mode == 'edit_route') {
			this.isRouteEditMode = true;
		}
		else if (mode == 'point_of_interest') {
			this.isPointOfInterestMode = true;
		}
		this.rebase();

	}

	@Input() overlapTrail: any[];
	plotRoute(fitToScreen?) {
		let _this = this;

		if (_this.activePolyline) {
			let trail = [];
			let idx = 0;
			for (let segment of (isArray(_this.activePolyline._latlngs[0]) ? _this.activePolyline._latlngs[0] : _this.activePolyline._latlngs)) {
				trail.push({
					latitude: segment.lat,
					longitude: segment.lng,
					order_number: idx
				});
				idx++;
			}
			if (_this.object) {
				_this.object.trail_points = trail;
			}
			else {
				_this.tmpRoute = trail;
			}
			console.log(trail);
			_this.map.removeLayer(_this.activePolyline);
		}

		//console.log(generateTrail());
		if ((_this.object ? _this.object.trail_points : _this.tmpRoute).length > 0) {
			let trailPointsArray = PolylineFeatures.generateTrail(_this.object, _this.tmpRoute);
			let polyline;
			if (fitToScreen) {
				polyline = PolylineFeatures.generatePolyline(trailPointsArray, (_this.external ? _this.defaultColor : _this.userColor), _this.canDragRoute(), true);//FullL.Leaf.polyline(trailPointsArray, { draggable: _this.canDragRoute(), interactive: _this.canDragRoute(), smoothFactor: 0, noClip: true, edit_with_drag: _this.canDragRoute(), snakingSpeed: 500 });
				_this.map.fitBounds(polyline.getBounds());
				polyline.addTo(_this.map);
			}
			else {
				polyline = PolylineFeatures.generatePolyline(trailPointsArray, (_this.external ? _this.defaultColor : _this.userColor), _this.canDragRoute(), false);//FullL.Leaf.polyline(trailPointsArray, { draggable: _this.canDragRoute(), interactive: _this.canDragRoute(), smoothFactor: 0, noClip: true, edit_with_drag: _this.canDragRoute() }).addTo(_this.map);
				polyline.addTo(_this.map);
			}
			_this.activePolyline = polyline;

			// _this.getRouteDistance();

			let enableEditAfterSnakeAnimation = () => {
				if (_this.activePolyline) {
					_this.map.removeLayer(_this.activePolyline);
				}
				polyline = PolylineFeatures.generatePolyline(trailPointsArray, (_this.external ? _this.defaultColor : _this.userColor), _this.canDragRoute(), false)//FullL.Leaf.polyline(trailPointsArray, { draggable: _this.canDragRoute(), interactive: _this.canDragRoute(), smoothFactor: 0, noClip: true, edit_with_drag: _this.canDragRoute() }).addTo(_this.map);
				// console.log(polyline);
				polyline.addTo(_this.map);
				_this.activePolyline = polyline;
				// _this.getRouteDistance();
				PolylineFeatures.onRouteClick(polyline);
				PolylineFeatures.onRouteDrag(polyline);
				_this.activePolyline.on('add', (e) => {
					console.log(e);
				});

			}

			enableEditAfterSnakeAnimation();

			// polyline.on('snakeend', (e) => {
			// 	//Forces polyline editability as snakein causes it to get removed.
			// 	if (!_this.external) {

			// 	}
			// });

		}

		if (_this.overlapTrail && _this.overlapTrail.length > 1) {
			let points = [];
			let idx = 0;
			for (let loc of _this.overlapTrail) {
				points.push({ ...loc, order_number: idx });
				idx++;
			}
			let userTrail = PolylineFeatures.generateTrail(null, points);
			let polyline = PolylineFeatures.generatePolyline(userTrail, _this.userColor, false, true);//FullL.Leaf.polyline(userTrail, { draggable: false,zIndex:100, interactive: false,color:'#ff0000', smoothFactor: 0, noClip: true, edit_with_drag: false, snakingSpeed: 500 }).addTo(_this.map).snakeIn();
			polyline.addTo(_this.map); //.snakeIn();
		}

	}

	tmpRoute: any[] = [];
	addRoute(lat, lng) {
		let _this = this;
		(_this.object ? _this.object.trail_points : _this.tmpRoute).push({
			elevation: undefined,
			latitude: lat,
			longitude: lng,
			order_number: (_this.object ? _this.object.trail_points : _this.tmpRoute).length,
			timestamp: moment()
		})
		if (_this.activePolyline) {
			_this.activePolyline._latlngs = [..._this.activePolyline._latlngs, { lat: lat, lng: lng, timestamp: moment() }];
		}
		console.log(_this.tmpRoute);
		_this.plotRoute();
	}

	//End of Route Section


	//Marker Section

	@ViewChild('deleteMarkerConfirm', { static: true }) deleteMarkerModal: ModalDirective;
	@ViewChild('editMarkerConfirm', { static: true }) editMarkerModal: ModalDirective;
	activeMarker: any;
	activePOIForm: FormGroup;
	activeImages: FormGroup;
	incomingPOIAdvertEvent: EventEmitter<any> = new EventEmitter<any>();
	incomingPOITypeEvent: EventEmitter<any> = new EventEmitter<any>();
	incomingPOIImageEvent: EventEmitter<any> = new EventEmitter<any>();
	incomingPilgrimGuideEvent: EventEmitter<any> = new EventEmitter<any>();
	triggerFetchAlt: EventEmitter<any> = new EventEmitter<any>();

	timeCreated() {
		const stringValue = this.object.created_at;
		let date = moment.utc(stringValue).local();
		let times = date.format('DD/MM/YYYY HH:mm:ss');
		return times;
	}

	timeUpdated() {
		const stringValue = this.object.updated_at;
		let date = moment.utc(stringValue).local();
		let times = date.format('DD/MM/YYYY HH:mm:ss');
		return times;
	}

	markerDataValid = {
		draggable: this.canDrag(),
		icon: FullL.Leaf.icon({
			iconSize: [30, 30],
			iconAnchor: [30, 30],
			//popupAnchor: [2, -40],
			iconUrl: "../../../../assets/img/marker-blue.svg"
		})
	};

	markerDataInvalid = {
		draggable: this.canDrag(),
		icon: FullL.Leaf.icon({
			iconSize: [30, 30],
			iconAnchor: [30, 30],
			//popupAnchor: [2, -40],
			iconUrl: "../../../../assets/img/marker-red.svg"
		})
	};

	is_imperial: boolean = false;
	getRouteDistance(): number {
		let _this = this;
		let calculateDistance = (_latlngs) => {
			// distance in meters
			var mDistance = 0,
				length = _latlngs.length;
			for (var i = 1; i < length; i++) {

				mDistance += _latlngs[i].distanceTo(_latlngs[i - 1]);


			}
			// optional
			// if (_this.is_imperial) {
			//   return mDistance / 1609.34;
			// } else {
			//   return mDistance / 1000;
			// }
			_this.form.get('distance').setValue((Math.round(mDistance)));
			_this.triggerFetch.emit(_this.form);
			//console.log(mDistance);
			return mDistance;
		}

		if (this.activePolyline) {
			let latLngs = this.activePolyline._latlngs;
			//let points = this.activePolyline._parts;
			let distance = calculateDistance(latLngs);
			//console.log("Polyline Total Distance in Meters: ", distance);
		}
		return 0;
	}

	switchMarkerState(destination) {
		let _this = this;
		if ((_this.object ? _this.object.trail_points : _this.tmpRoute).length == 0) {
			return _this.markerDataValid;
		}
		for (let trailPoint of (_this.object ? _this.object.trail_points : _this.tmpRoute)) {
			let from = { lat: trailPoint.latitude, lng: trailPoint.longitude };
			let to = { lat: destination.latitude, lng: destination.longitude };
			let proximityValue = this.checkMarkerToRouteProximity(from, to);
			//console.log(proximityValue);
			if (proximityValue <= 250) {
				console.log("In Proximity: ", proximityValue);
				return _this.markerDataValid;
			}
		}
		return _this.markerDataInvalid;
	}

	isMarkerFarFromRoute() {
		if (this.activeMarker) {
			//console.log(this.activeMarker);
			if (this.activeMarker['poi_data']['is_far']) {
				return true;
			}
		}
		return false;
	}

	addMarker(poi, forceEdit?: boolean) {
		console.log("Adding marker");
		let _this = this;
		var marker = FullL.Leaf.marker([poi.latitude, poi.longitude], this.markerDataValid);
		marker.options.icon = _this.switchMarkerState(poi).icon;
		marker['poi_data'] = poi;
		marker['poi_data']['is_far'] = false;
		if (marker.options.icon.options.iconUrl.includes("red")) {
			marker['poi_data']['is_far'] = true;
		}

		marker.addTo(this.map);
		this.stops.push(marker);
		this.onMarkerDrag(marker);
		this.onMarkerClick(marker);
		if (forceEdit) {
			this.setMarker(marker);
		}
	}


	checkMarkerToRouteProximity(from, to) {
		// console.log(from);
		// console.log(to);
		const R = 6371e3; // metres
		const φ1 = from.lat * Math.PI / 180; // φ, λ in radians
		const φ2 = to.lat * Math.PI / 180;
		const Δφ = (to.lat - from.lat) * Math.PI / 180;
		const Δλ = (to.lng - from.lng) * Math.PI / 180;

		const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
			Math.cos(φ1) * Math.cos(φ2) *
			Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const d = R * c; // in metres
		return d;
	}

	onMarkerDrag(marker) {
		let _this = this;
		marker.on("dragend", function (e) {
			var position = e.target.getLatLng();
			let toLocation = { latitude: position.lat, longitude: position.lng };
			let dragIcon = _this.switchMarkerState(toLocation).icon;
			marker['poi_data'] = { ...marker['poi_data'], ...toLocation };
			marker['poi_data']['is_far'] = false;
			console.log(marker);
			if (dragIcon.options.iconUrl.includes("red")) {
				marker['poi_data']['is_far'] = true;
			}
			marker.setIcon(dragIcon);
			_this.setMarker(marker);
		});
	}

	onMarkerClick(marker) {
		let _this = this;
		marker.on("click", function (e) {
			var marker = e.target;
			_this.setMarker(marker);
			console.log(_this.activePOIForm);
		});
	}

	setMarker(marker) {
		let _this = this;
		if (this.external) {
			return;
		}
		_this.activeMarker = marker;
		
		let poi = marker['poi_data'];
		_this.activePOIForm = PointOfInterestModel.getFormGroup(poi);
		_this.cardImageBase64 = null;
		_this.isImageSaved = false;
		setTimeout(() => {
			Utils.clearPaginatedSelects(_this.paginatedSelects);
			if (poi['advert']) {
				_this.incomingPOIAdvertEvent.emit(poi['advert']);
			}
			if (poi['type']) {
				_this.incomingPOITypeEvent.emit(poi['type']);
			}
			// if (poi['path']) {
			// 	_this.incomingPOIImageEvent.emit(poi['path']);
			// }
			if (poi['pilgrim_guide']) {
				_this.incomingPilgrimGuideEvent.emit(poi['pilgrim_guide']);
			}
			_this.triggerFetchAlt.emit(_this.activePOIForm);
			_this.editMarkerModal.show();
		}, 100);
	}

	getStops() {
		let filteredStops = this.stops.filter(val => {
			if (val['poi_data']['title']) {
				return val;
			}
		});
		//console.log(filteredStops);
		return filteredStops;
	}

	confirmPOIDelete() {
		let validMarker: boolean = this.activeMarker['poi_data']['title'] ? true : false;
		let InvalidMarker: boolean = this.activeMarker['poi_data']['title'] ? false : true;
		this.editMarkerModal.hide();
		if (validMarker || InvalidMarker) {
			this.deleteMarkerModal.show();
		}
	}

	savePOI() {
		let _this = this;
		//this.activePOIForm.get('type').value


		if (!_this.object) {
			_this.activeMarker['poi_data'] = _this.activePOIForm.getRawValue();
			//_this.utils.errorMessage("Point of interest failed to update");
			_this.editMarkerModal.hide();
			return;
		}

		if (!_this.isPOIAdvert()) {
			_this.activePOIForm.get('advert').reset();
		}

		if (!_this.isPOIPilgrim()) {
			_this.activePOIForm.get('pilgrim_guide').reset();
		}

		if (_this.activePOIForm.get('type').value.invalid) {
			_this.activePOIForm.markAllAsTouched();
			_this.utils.errorMessage("Invalid POI types.");
			console.log(_this.activePOIForm);
			Utils.forceTouchPaginatedSelects(_this.paginatedSelects);
			return;
		}
		//_this.editMarkerModal.hide();
		_this.activeMarker['poi_data'] = _this.activePOIForm.getRawValue();

		if (_this.activeMarker['poi_data']['advert']) {
			_this.advertService.get(_this.activeMarker['poi_data']['advert']).subscribe(res => {
				_this.activeMarker['poi_data']['advert'] = res['data'];
			},
				err => {
					console.log(err);
				})
		}

		_this.poiTypeService.get(_this.activeMarker['poi_data']['type']).subscribe(res => {
			_this.activeMarker['poi_data']['type'] = res['data'];
		},
			err => {
				console.log(err);
			})
		console.log(_this.stops);

		let pois = [];
		if (_this.stops && _this.stops.length > 0) {
			for (let poi of _this.stops) {
				if (poi['poi_data']['title']) {
					pois.push(poi['poi_data']);
				}
			}
		}

		let filteredPOI: any[] = [];
		for (let poi of pois) {
			if (poi['type'] && poi['type']['id']) {
				// poi['type'] = poi['type']['id'];
				let poiAlt = { ...poi, type: poi['type']['id'] };
				if (poiAlt['advert'] && poiAlt['advert']['id']) {
					poiAlt['advert'] = poiAlt['advert']['id'];
				}
				filteredPOI = [...filteredPOI, poiAlt];
			}
			else {
				filteredPOI = [...filteredPOI, poi];
			}
		}

		if (_this.object) {
			_this.service.editPOI(_this.object.id, { poi: filteredPOI }).subscribe(res => {
				console.log(res);
				_this.editMarkerModal.hide();
				this.photoChangeEventPOI(_this.activeMarker['poi_data']['path']);
				// _this.poiTypeService.get(_this.activeMarker['poi_data']['path'])
				_this.utils.successMessage("Point of Interest Updated.");
				//this.utils.errorMessage(res['errors'][0]);
			},
				err => {
					_this.utils.errorMessage("Point of Interest Failed To Update.");
					console.log(err);
				})
		}

	}

	deleteMarker() {
		if (this.activeMarker && this.stops) {
			for (let stop of this.stops) {
				if (stop._leaflet_id == this.activeMarker._leaflet_id) {
					stop['poi_data'] = { latitude: 1, longitude: 1 };
				}
			}
		}
		this.clearInvalidMarkers();
		this.map.removeLayer(this.activeMarker);
		this.deleteMarkerModal.hide();
	}

	@ViewChild('clearAllConfirm', { static: true }) clearAllModal: ModalDirective;
	clearAll() {
		let _this = this;
		if (_this.object) {
			_this.object.trail_points = [];
		}
		else {
			_this.tmpRoute = [];
		}
		for (let stop of _this.stops) {
			this.map.removeLayer(stop);
		}
		_this.stops = [];
		if (_this.activePolyline) {
			_this.map.removeLayer(_this.activePolyline);
		}
		_this.activePolyline = null;
		_this.clearAllModal.hide();
	}

	clearInvalidMarkers() {
		return;
		let idxs = [];
		let idx = 0;
		for (let stop of this.stops) {
			let stopData = stop['poi_data'];
			let isInvalidStop = (!stopData['title'] || !stopData['description'] || !stopData['type']) ? true : false;
			if (isInvalidStop) {
				//this.map.removeLayer(stop);
				idxs.push(idx);
				console.log("Error occured");
			}
			idx++;
		}
		for (let id of idxs) {
			this.stops.splice(id, 1);
		}
	}

	// End of Marker Section

	//Map Section
	Mapview() {
		let _this = this;

		if (this.map) {
			//to remove any initialization of a previous map
			this.map.off();
			this.map.remove();
			this.mapview = !this.mapview;
		}
		var originalTile = this.getMapStyling();
		this.map = FullL.Leaf.map('map', {
			//choose the default view coordinates
			center: [35.935328, 14.454524],
			//choose the zooming level
			zoom: 12,
			//to remove the attribution
			attributionControl: false,
			//to add predefined layers

			layers: originalTile,
			zoomControl: false
		});

		_this.plotRoute();
		//console.log(_this.activePOIForm);
		this.initializeDataAfterLoad();
		let startOnMapClick = () => {
			_this.form.markAsTouched();
			_this.map.on("click", e => {
				console.log(e);

				if (!_this.isRouteEditMode) {
					if (_this.isRouteMode) {
						_this.addRoute(e.latlng.lat, e.latlng.lng);
					}
					else if (_this.isPointOfInterestMode) {
						_this.addMarker({ latitude: e.latlng.lat, longitude: e.latlng.lng });
					}
				}
			});
			_this.map.on("mousemove", e => {
				//console.log(e);
				if (!_this.isRouteEditMode) {
					if (_this.isRouteDeleteMode && _this.shouldDrawPolyLive) {
						_this.deletePolSecond = null;
						_this.handleRouteDeleteMode(e);
					}
				}
			});
			_this.map.on("mousedown", e => {
				//console.log(e);
				if (!_this.isRouteEditMode) {
					if (_this.isRouteDeleteMode) {
						this.cancelDelete();
						_this.map.dragging.disable();
						_this.shouldDrawPolyLive = true;
						_this.handleRouteDeleteMode(e);
					}
				}
			});
			_this.map.on("mouseup", e => {
				//console.log(e);
				if (!_this.isRouteEditMode) {
					if (_this.isRouteDeleteMode) {
						_this.map.dragging.enable();
						// _this.map.dragging = true;÷
						_this.handleRouteDeleteMode(e);
						_this.shouldDrawPolyLive = false;
					}
				}
			});
		}

		startOnMapClick();

	}

	initiateMap() {
		let _this = this;
		//to prevent errors
		if (this.map) {
			//to remove any initialization of a previous map
			this.map.off();
			this.map.remove();
			//this.mapview =! this.mapview;
		}
		// tiles are used to load and display tile layers on the map.
		var originalTile = this.getMapStyling();
		// declaring the leaflet map
		this.map = FullL.Leaf.map('map', {
			//choose the default view coordinates
			center: [35.935328, 14.454524],
			//choose the zooming level
			zoom: 12,
			//to remove the attribution
			attributionControl: false,
			//to add predefined layers
			layers: originalTile,
			zoomControl: false
		});

		let startOnMapClick = () => {
			_this.form.markAsTouched();
			_this.map.on("click", e => {
				console.log(e);

				if (!_this.isRouteEditMode) {
					if (_this.isRouteMode) {
						_this.addRoute(e.latlng.lat, e.latlng.lng);
					}
					else if (_this.isRouteDeleteMode) {
						// _this.shouldDrawPolyLive = true;
						// _this.handleRouteDeleteMode(e);
					}
					else if (_this.isPointOfInterestMode) {
						_this.addMarker({ latitude: e.latlng.lat, longitude: e.latlng.lng }, true);
					}
				}
			});
			_this.map.on("mousemove", e => {
				//console.log(e);
				if (!_this.isRouteEditMode) {
					if (_this.isRouteDeleteMode && _this.shouldDrawPolyLive) {
						_this.deletePolSecond = null;
						_this.handleRouteDeleteMode(e);
					}
				}
			});
			_this.map.on("mousedown", e => {
				//console.log(e);
				if (!_this.isRouteEditMode) {
					if (_this.isRouteDeleteMode) {
						this.cancelDelete();
						_this.map.dragging.disable();
						_this.shouldDrawPolyLive = true;
						_this.handleRouteDeleteMode(e);
					}
				}
			});
			_this.map.on("mouseup", e => {
				//console.log(e);
				if (!_this.isRouteEditMode) {
					if (_this.isRouteDeleteMode) {
						_this.map.dragging.enable();
						// _this.map.dragging = true;÷
						_this.handleRouteDeleteMode(e);
						_this.shouldDrawPolyLive = false;
					}
				}
			});
		}

		startOnMapClick();
	}

	deletePolFirst = null;
	deletePolSecond = null;
	deletePloygon = null;
	shouldDrawPolyLive = false;

	handleRouteDeleteMode(e) {

		if (this.deletePloygon != null && this.shouldDrawPolyLive == false) {
			this.map.removeLayer(this.deletePloygon);
			this.deletePolFirst = null;
			this.deletePolSecond = null;
			this.deletePloygon = null;
		}

		if (this.deletePolFirst == null) {
			this.deletePolFirst = [e.latlng.lat, e.latlng.lng];
		}
		else if (this.deletePolSecond == null) {
			this.deletePolSecond = [e.latlng.lat, e.latlng.lng];
			var latlngs = [this.deletePolFirst, [this.deletePolFirst[0], this.deletePolSecond[1]], this.deletePolSecond, [this.deletePolSecond[0], this.deletePolFirst[1]]];

			if (this.deletePloygon != null) {
				this.map.removeLayer(this.deletePloygon);
			}

			this.deletePloygon = FullL.Leaf.polygon(latlngs, { color: 'red' }).addTo(this.map);
		}

	}

	cancelDelete() {
		if (this.deletePloygon != null) {

			this.map.removeLayer(this.deletePloygon);
			this.deletePolFirst = null;
			this.deletePolSecond = null;
			this.deletePloygon = null;
		}
	}

	executeDelete() {

		let bounds = this.deletePloygon.getBounds();

		let newPolyLine = [];

		for (var point of this.activePolyline.getLatLngs()) {
			if (!bounds.contains(point)) {
				newPolyLine.push(point);
			}
		}

		if (this.activePolyline) {
			this.map.removeLayer(this.activePolyline);
		}

		let polyline = PolylineFeatures.generatePolyline(newPolyLine, (this.external ? this.defaultColor : this.userColor), this.canDragRoute(), false);
		polyline.addTo(this.map);
		this.activePolyline = polyline;


		this.map.removeLayer(this.deletePloygon);
		this.deletePolFirst = null;
		this.deletePolSecond = null;
		this.deletePloygon = null;
	}

	@ViewChild('generateRouteModal', { static: true }) generateRoute: ModalDirective;
	markerFrom: any;
	markerTo: any;
	extraMarkers: any[] = [];
	secondaryPolyline: any;

	addSecondaryMarker() {
		let _this = this;
		// let marker = FullL.Leaf.marker([35.904151, 14.414114], this.markerDataInvalid);
		// this.extraMarkers.push(marker);
		// marker.addTo(this.map2);
		// console.log("Marker Added");
		// marker.on("dragend", function (e) {
		//   var marker = e.target;
		//   console.log("Route Plot Marker Drag:");
		//   _this.initializeSecondaryPolyline();
		// });


		let marker = FullL.Leaf.marker([35.904151, 14.414114], this.markerDataInvalid);
		this.extraMarkers.push(marker);
		marker.addTo(this.map2);
		marker.on("dragend", function (e) {
			var marker = e.target;
			console.log("Route Plot Marker Drag:");
			_this.initializeSecondaryPolyline();
		});

		marker.on("click", function (e) {
			//var marker = e.target;
			_this.activeRouteMarker = marker;
			_this.deleteExtraMarkerModal.show();
		});
	}

	deleteExtraMarkerAlt() {
		let idx = 0;
		if (this.activeRouteMarker && this.extraMarkers) {
			for (let stop of this.extraMarkers) {
				if (stop._leaflet_id == this.activeRouteMarker._leaflet_id) {
					break;
				}
				idx++;
			}
		}
		this.extraMarkers.splice(idx, 1);
		this.map2.removeLayer(this.activeRouteMarker);
		this.deleteExtraMarkerModal.hide();
		this.initializeSecondaryPolyline();
	}

	@ViewChild('deleteExtraMarker', { static: true }) deleteExtraMarkerModal: ModalDirective;
	activeRouteMarker: any;

	clearMapPolylines() {
		let _this = this;
		for (let i in _this.map2._layers) {
			if (_this.map2._layers[i]._path != undefined) {
				try {
					_this.map2.removeLayer(_this.map2._layers[i]);
				}
				catch (e) {
					//console.log("problem with " + e + m._layers[i]);
				}
			}
		}
	}

	subscriptions: any[] = [];
	activeSub: Subscription;
	initializeSecondaryPolyline() {
		let _this = this;
		if (this.activeSub) {
			return;
		}
		// if(this.activeSub){
		//   this.activeSub.unsubscribe();
		//   this.subscriptions = [];
		// }

		if (_this.secondaryPolyline) {
			_this.map2.removeLayer(_this.secondaryPolyline);
			//console.log("Removing Old Polyline.");
		}
		let from = { latitude: _this.markerFrom._latlng.lat, longitude: _this.markerFrom._latlng.lng };
		let to = { latitude: _this.markerTo._latlng.lat, longitude: _this.markerTo._latlng.lng };

		if (!_this.extraMarkers || _this.extraMarkers.length == 0) {
			_this.service.generateRoute(from, to).subscribe(res => {

				let points = [];
				let idx = 0;
				for (let loc of res['data']) {
					points.push({ ...loc, order_number: idx });
					idx++;
				}

				let trailPointsArray = PolylineFeatures.generateTrail(null, points);
				console.log(_this.secondaryPolyline);
				_this.secondaryPolyline = PolylineFeatures.generatePolyline(trailPointsArray, (_this.external ? _this.defaultColor : _this.userColor), _this.canDragRoute(), false)//FullL.Leaf.polyline(trailPointsArray, { draggable: _this.canDragRoute(), interactive: _this.canDragRoute(), smoothFactor: 0, noClip: true, edit_with_drag: false });
				_this.secondaryPolyline.addTo(_this.map2);
			},
				err => {
					console.log(err);
				})
		}
		else {

			let firstRoute = { latitude: _this.extraMarkers[0]._latlng.lat, longitude: _this.extraMarkers[0]._latlng.lng };
			let lastRoute = { latitude: _this.extraMarkers[_this.extraMarkers.length - 1]._latlng.lat, longitude: _this.extraMarkers[_this.extraMarkers.length - 1]._latlng.lng };

			let counter = 0;

			_this.subscriptions.push({ data: [from, firstRoute], sub: _this.service.generateRoute(from, firstRoute) });

			for (let extra of this.extraMarkers) {
				if (_this.extraMarkers[counter + 1]) {
					let nextExtra = _this.extraMarkers[counter + 1];
					let thisRoute = { latitude: extra._latlng.lat, longitude: extra._latlng.lng };
					let nextRoute = { latitude: nextExtra._latlng.lat, longitude: nextExtra._latlng.lng };
					let subscription = _this.service.generateRoute(thisRoute, nextRoute);
					_this.subscriptions.push({ data: [thisRoute, nextRoute], sub: subscription });
				}
				counter++;
			}

			_this.subscriptions.push({ data: [lastRoute, to], sub: _this.service.generateRoute(lastRoute, to) });

			let points = [];
			let idx = 0;
			let loadData = () => {
				//console.log(_this.subscriptions);
				if (_this.subscriptions && _this.subscriptions.length > 0) {
					console.log("Load Data");
					let nextSubscription = _this.subscriptions[0]['sub'];
					_this.activeSub = nextSubscription.subscribe(res => {
						for (let loc of res['data']) {
							points.push({ ...loc, order_number: idx });
							idx++;
						}
						_this.subscriptions.splice(0, 1);
						loadData();
						_this.activeSub = null;
					},
						err => {
							_this.subscriptions.splice(0, 1);
							console.log(err);
							_this.activeSub = null;
						})
				}
				else {
					plotData();
				}
			}

			loadData();


			let plotData = () => {
				_this.clearMapPolylines();
				let trailPointsArray = PolylineFeatures.generateTrail(null, points);
				_this.secondaryPolyline = PolylineFeatures.generatePolyline(trailPointsArray, (_this.external ? _this.defaultColor : _this.userColor), _this.canDragRoute(), false)//FullL.Leaf.polyline(trailPointsArray, { draggable: _this.canDragRoute(), interactive: _this.canDragRoute(), smoothFactor: 0, noClip: true, edit_with_drag: false });
				_this.secondaryPolyline.addTo(_this.map2);
			}


		}
	}

	openSecondaryMap() {
		let _this = this;
		this.generateRoute.show();

		// if (this.extraMarkers) {
		//   for (let m of this.extraMarkers) {
		//     this.map2.removeLayer(m);
		//   }
		// }
		// this.extraMarkers = [];

		this.generateRoute.onHide.subscribe(res => {
			if (_this.markerFrom) {
				_this.map2.removeLayer(_this.markerFrom);
			}
			if (_this.markerTo) {
				_this.map2.removeLayer(_this.markerTo);
			}
		})

		var originalTile = this.getMapStyling();
		let fromLat = 35.904151, fromLng = 14.414114;
		let toLat = 35.879242, toLng = 14.434472;

		console.log(_this.activePolyline);
		if (_this.activePolyline && _this.activePolyline._latlngs && _this.activePolyline._latlngs.length >= 2) {
			console.log(fromLat = _this.activePolyline._latlngs[0]);
			fromLat = _this.activePolyline._latlngs[0].lat;
			fromLng = _this.activePolyline._latlngs[0].lng;
			toLat = _this.activePolyline._latlngs[_this.activePolyline._latlngs.length - 1].lat;
			toLng = _this.activePolyline._latlngs[_this.activePolyline._latlngs.length - 1].lng;
		}
		else if (_this.activePolyline && _this.activePolyline._latlngs[0] && _this.activePolyline._latlngs[0].length >= 2) {
			fromLat = _this.activePolyline._latlngs[0][0].lat;
			fromLng = _this.activePolyline._latlngs[0][0].lng;
			toLat = _this.activePolyline._latlngs[_this.activePolyline._latlngs[0][_this.activePolyline._latlngs[0].length - 1]].lat;
			toLng = _this.activePolyline._latlngs[_this.activePolyline._latlngs[0][_this.activePolyline._latlngs[0].length - 1]].lng;
		}

		let initializeMapMarkers = () => {
			let initializeFrom = () => {
				this.markerFrom = FullL.Leaf.marker([fromLat, fromLng], _this.markerDataValid);
				this.markerFrom.addTo(this.map2);
				this.markerFrom.on("dragend", function (e) {
					var marker = e.target;
					console.log("Route Plot Marker Drag:");
					_this.initializeSecondaryPolyline();
				});

				this.markerFrom.on("click", function (e) {
					var marker = e.target;
					console.log("Route Plot Marker Click:");
				});
			}
			let initializeTo = () => {
				this.markerTo = FullL.Leaf.marker([toLat, toLng], _this.markerDataInvalid);
				this.markerTo.addTo(this.map2);
				this.markerTo.on("dragend", function (e) {
					var marker = e.target;
					console.log("Route Plot Marker Drag:");
					_this.initializeSecondaryPolyline();
				});

				this.markerTo.on("click", function (e) {
					var marker = e.target;
					console.log("Route Plot Marker Click:");
				});
			}
			initializeFrom();
			initializeTo();
			_this.initializeSecondaryPolyline();
			//let polyline = FullL.Leaf.polyline(trailPointsArray, { draggable: _this.canDragRoute(), interactive: _this.canDragRoute(), smoothFactor: 0, noClip: true }).addTo(_this.map2);
		}

		if (!this.map2) {
			this.map2 = FullL.Leaf.map('map2', {
				//choose the default view coordinates
				center: [35.935328, 14.454524],
				//choose the zooming level
				zoom: 12,
				//to remove the attribution
				attributionControl: false,
				//to add predefined layers
				layers: originalTile,
				zoomControl: false
			});
		}
		setTimeout(function () {
			_this.map2.invalidateSize();
			initializeMapMarkers();
		}, 500);
	}

	plotSecondaryRoute() {
		let _this = this;
		this.generateRoute.hide();
		if (_this.secondaryPolyline) {
			_this.map2.removeLayer(_this.secondaryPolyline);
		}
		if (this.activePolyline) {
			_this.map.removeLayer(_this.activePolyline);
		}
		_this.map2.removeLayer(_this.markerFrom);
		_this.map2.removeLayer(_this.markerTo);
		_this.activePolyline = _this.secondaryPolyline;
		_this.activePolyline.addTo(_this.map);
		_this.map.fitBounds(_this.activePolyline.getBounds());
	}

	//End of Map Section

	difficulties: any[] = [
		{
			title: "Easy",
			text: "easy"
		},
		{
			title: "Moderate",
			text: "moderate"
		},
		{
			title: "Difficult",
			text: "difficult"
		},
		{
			title: "Challenging",
			text: "challenging"
		}
	];

	@Input() external: boolean = false;
	@Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
	@Input() object: RouteModel;
	roles: any[];
	@ViewChild('deleteModal', { static: true }) modalDelete: ModalDirective;
	@ViewChild('publishModal', { static: true }) modalPublish: ModalDirective;
	@ViewChild('publishInternallyModal', { static: true }) modalInternallyPublish: ModalDirective;
	@ViewChild('unpublishModal', { static: true }) modalUnpublish: ModalDirective;
	@ViewChild('unpublishInternallyModal', { static: true }) modalInternallyUnpublish: ModalDirective;
	errors: String[] = [];
	data = new DeviceBuildData();
	module = this.data.module;
	form: FormGroup;
	public map;
	public test;
	public map2;
	isRouteEditMode: boolean = false;
	isRouteMode: boolean = false;
	distancePrefix = Utils.distancePrefix;
	durationPrefix = Utils.durationPrefix;
	mapview: boolean = true;

	constructor(
		private service: RouteService,
		public pilgrimGuideService: PilgrimGuideService,
		public poiTypeService: POITypeService,
		public advertService: AdvertService,
		private router: Router,
		private snapshot: ActivatedRoute,
		private authService: AuthService,
		private messageService: MessageService,
		private errorHandler: ErrorHandlingService,
		private utils: Utils,
		private cdr: ChangeDetectorRef,
		public permissionService: PermissionService
	) {
	}

	getGallery() {
		return this.form.get('gallery').value;
	}

	activeIndex: number;
	@ViewChild('deleteModalImage', { static: true }) imageDelete: ModalDirective;

	startImageDelete(index) {
		this.activeIndex = index;
		this.imageDelete.show();
	}

	deleteImage() {
		let gallery = this.form.get('gallery').value;
		gallery.splice(this.activeIndex, 1);
		this.form.get('gallery').setValue(gallery);
		this.imageDelete.hide();
	}

	@ViewChild('uploadSection', { static: false }) uploadSectionComponent: pgUploadComponent;
	handleChange(data) {
		let gallery = this.form.get('gallery').value;
		if (gallery) {
			gallery = [...gallery, data['file']];
		}
		else {
			gallery = [data['file']];
		}
		this.form.get('gallery').setValue(gallery);
		this.uploadSectionComponent.FileList = [];
		console.log(this.form.get('gallery').value);
	}

	photoChangeEvent(fileInput: any) {
		let _this = this;
		Utils.handleFileChange(fileInput).subscribe(res => {
			let success = res.success;
			if (success) {
				_this.cardImageBase64 = res.cardImageBase64;
				_this.isImageSaved = res.isImageSaved;
				_this.form.get('outer_image').setValue(res.cardImageBase64);
			}
			else {
				console.log("Failed to load file.");
			}
		});
	}

	photoChangeEventAlt(fileInput: any) {
		let _this = this;
		Utils.handleFileChange(fileInput).subscribe(res => {
			let success = res.success;
			if (success) {
				_this.cardImageBase64Alt = res.cardImageBase64;
				_this.isImageSavedAlt = res.isImageSaved;
				_this.form.get('outer_image').setValue(res.cardImageBase64);
			}
			else {
				console.log("Failed to load file.");
			}
		});
	}

	photoChangeEventPOI(fileInput: any) {
		let _this = this;
		Utils.handleFileChange(fileInput).subscribe(res => {
			let success = res.success;
			if (success) {

				//_this.cardImageBase64POI = res.cardImageBase64;
				_this.isImageSavedPOI = res.isImageSaved;
				_this.activeMarker['poi_data']['image'] = res.cardImageBase64;
				_this.activePOIForm.get('image').setValue(res.cardImageBase64);

			}
			if (success) {

				//_this.cardImageBase64POI = res.cardImageBase64;
				_this.isImageSavedPOI = res.isImageSaved;
				_this.activeMarker['poi_data']['path'] = res.cardImageBase64;
				_this.activePOIForm.get('path').setValue(res.cardImageBase64);

			}
			else {
				console.log("Failed to load file.");

			}
		});
	}

	ngOnInit() {
		let _this = this;
		this.createForm({});
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.messageService.remove();
			}
		});
		this.snapshot.paramMap.subscribe(res => {
			if (res['params']['id'] && !_this.external) {
				this.loadData(res['params']['id']);
			}
		})
		//
		//to prevent errors
		this.initiateMap();
		//this.testing();
		this.editMarkerModal.onHide.subscribe(res => {
			// _this.clearInvalidMarkers();
		});
	}

	getMapStyling() {
		if (this.mapview == true) {
			return FullL.Leaf.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
				attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
				subdomains: 'abcd',
				maxZoom: 20
			});

		}
		else if (this.mapview == false) {
			return FullL.Leaf.tileLayer('https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}', {
				attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
				subdomains: 'abcd',
				maxZoom: 20
			}
			);
		}

	}


	// tiles are used to load and display tile layers on the map.
	originalTile = this.getMapStyling();

	menuItems: any[] = [
		{ action: 'delete', message: 'Delete ' + this.module.name, class: '', icon: 'fal fa-trash', permissions: ['delete_' + this.module.permission] },
		{ action: 'publish', message: 'Publish ' + this.module.name, class: '', icon: 'fal fa-upload', permissions: ['create_' + this.module.permission] },
		{ action: 'publishinternally', message: 'Publish Internally ', class: '', icon: 'fal fa-arrow-from-bottom', permissions: ['create_' + this.module.permission] },
		{ action: 'unpublish', message: 'Unpublish ' + this.module.name, class: '', icon: 'fal fa-download', permissions: ['create_' + this.module.permission] },
		{ action: 'unpublishinternally', message: 'Unpublish Internally ', class: '', icon: 'fal fa-arrow-to-bottom', permissions: ['create_' + this.module.permission] }
	];

	stops: any[] = [];

	actionsMenuItems() {
		if (this.object.is_published == false && this.object.is_internally_published == false) {
			this.menuItems.splice(3, 2);
		} else if (this.object.is_published == true && this.object.is_internally_published == true) {
			this.menuItems.splice(1, 2);
		} else
			if (this.object.is_published === true) {

				this.menuItems.splice(1, 1),
					this.menuItems.splice(3, 1);
			} else

				if (this.object.is_internally_published === true) {
					this.menuItems.splice(2, 2);
				}


	}

	canUndo(): boolean {
		let _this = this;
		//console.log("Undo: ",(_this.route ? _this.route.trail_points : _this.tmpRoute));
		return (_this.object ? _this.object.trail_points : _this.tmpRoute).length > 0;
	}

	rebase() {
		let _this = this;
		if (this.activePolyline) {
			//this.map.fitBounds(this.activePolyline.getBounds());
		}
		_this.plotRoute();
		for (let stop of _this.stops) {
			stop.options.icon = _this.switchMarkerState(stop['poi_data']).icon;
		}
	}

	handleButtonPress = (event) => {
		switch (event) {
			case "delete":
				this.modalDelete.show();
				break;
			case "publish":
				this.modalPublish.show();
				break;
			case "publishinternally":
				this.modalInternallyPublish.show();
				break;
			case "unpublish":
				this.modalUnpublish.show();
				break;
			case "unpublishinternally":
				this.modalInternallyUnpublish.show();
				break;
			default:
				console.log(event);
				break;
		}
	}


	incomingEvent: EventEmitter<any> = new EventEmitter<any>();
	incomingPaginatedSelectValue(fieldName, value) {
		let _this = this;
		console.log(fieldName);
		console.log(this.form);
		_this.form.get(fieldName).setValue(value);
	}

	incomingPaginatedSelectValuePOI(fieldName, value) {
		let _this = this;
		console.log(fieldName);
		console.log(this.form);
		_this.activePOIForm.get(fieldName).setValue(value);
	}

	markTouched() {
		this.form.markAsTouched();
	}

	imageError: string;
	isImageSaved: boolean;
	cardImageBase64: string;
	cardImageBase64POI: string;
	//
	imageErrorAlt: string;
	isImageSavedAlt: boolean;
	isImageSavedPOI: boolean;
	cardImageBase64Alt: string;

	fileChangeEvent(fileInput: any) {
		let _this = this;
		Utils.handleFileChange(fileInput).subscribe(res => {
			let success = res.success;
			if (success) {
				_this.cardImageBase64 = res.cardImageBase64;
				_this.isImageSaved = res.isImageSaved;
			}
			// if (success) {
			// 	_this.cardImageBase64POI = res.cardImageBase64;
			// 	_this.isImageSavedPOI = res.isImageSaved;
			// }
			else {
				console.log("Failed to load file.");
			}
		});
	}

	@ViewChild('fromPicker', { static: true }) fromPicker: pgDatePickerComponent;
	@ViewChild('toPicker', { static: true }) toPicker: pgDatePickerComponent;
	fromDate: Date = new Date();
	toDate: Date = moment(new Date()).add(1, 'hour').toDate();
	forceRefresh(dateEvent) {
		let _this = this;
		Utils.forceRefresh(dateEvent, this.fromDate, this.toDate, this.fromPicker, this.toPicker).subscribe(res => {
			_this.cdr.detectChanges();
		})
	}

	_disabledStartDate = (startValue) => {
		return Utils._disabledStartDate(startValue, this.fromDate, this.toDate);
	};

	_disabledEndDate = (endValue) => {
		return Utils._disabledEndDate(endValue, this.fromDate, this.toDate);
	};

	removeImage() {
		this.cardImageBase64 = null;
		this.isImageSaved = false;
	}

	createForm(input, form?) {
		input = input || {};
		form ? form : this.form = RouteModel.getFormGroup(input);
	}

	openFileUpload(ele) {
		$(ele).click();
	}

	hasPermission(permission) {
		return this.authService.hasPermission(permission);
	}

	isTouched(controlName, form?) {
		return Utils.isFormTouched((form ? form : this.form), controlName);
	}

	isPOIAdvert() {
		let _this = this;
		let toggleAdvertRequired = (isRequired: boolean) => {
			if (isRequired) {
				_this.activePOIForm.get('advert').setValidators(Validators.required);
			}
			else {
				_this.activePOIForm.get('advert').clearValidators();
			}
			_this.activePOIForm.get('advert').updateValueAndValidity();
		}

		if (this.activePOIForm.get('type') && this.activePOIForm.get('type').value) {
			let type = this.activePOIForm.get('type').value;
			if (type['id']) {
				if (type['id'] == 5) {
					toggleAdvertRequired(true);
					return true;
				}
			}
			else {
				if (type == 5) {
					toggleAdvertRequired(true);
					return true;
				}
			}
		}
		toggleAdvertRequired(false);
		return false;
	}

	isPOIPilgrim() {
		let _this = this;
		let togglePilgrimRequired = (isRequired: boolean) => {
			if (isRequired) {
				_this.activePOIForm.get('pilgrim_guide').setValidators(Validators.required);
			}
			else {
				_this.activePOIForm.get('pilgrim_guide').clearValidators();
			}
			_this.activePOIForm.get('pilgrim_guide').updateValueAndValidity();
		}

		if (this.activePOIForm.get('type') && this.activePOIForm.get('type').value) {
			let type = this.activePOIForm.get('type').value;
			if (type['id']) {
				if (type['id'] == 6) {
					togglePilgrimRequired(true);
					return true;
				}
			}
			else {
				if (type == 6) {
					togglePilgrimRequired(true);
					return true;
				}
			}
		}
		togglePilgrimRequired(false);
		return false;
	}

	loading = {
		isFirstLoad: false,
		isLoading: false
	};

	undoLastRoutePoint() {
		let _this = this;
		let didUndo = PolylineFeatures.undoLastRoutePoint(this.canUndo(), this.tmpRoute, this.map, this.object, this.form, this.activePolyline);
		if (!didUndo) {
			_this.utils.errorMessage("There are no points left to undo on this route.");
		}
		else {
			_this.plotRoute();
		}
	}

	loadData(id) {
		let _this = this;
		let checkLoadingSpinner = () => {
			if (!_this.loading.isFirstLoad) {
				_this.loading.isFirstLoad = true;
				_this.loading.isLoading = true;
			}
		}

		checkLoadingSpinner();

		this.service.get(id).subscribe(res => {
			_this.object = new RouteModel(res['data']);
			this.service.getTrail(id).subscribe(res => {
				_this.object.trail_points = res['data'];
				_this.initializeDataAfterLoad();
				_this.createForm(_this.object);
				_this.triggerFetch.emit(_this.form);
				_this.loading.isLoading = false;
				//_this.isImageSaved = true;
				this.actionsMenuItems();
			});

		},
			err => {
				_this.loading.isLoading = false;
				console.log(err);
			});
	}

	initializeDataAfterLoad() {
		let _this = this;
		let populatePOI = () => {
			if (_this.object && _this.object.poi && _this.object.poi.length > 0) {
				for (let poi of _this.object.poi) {
					_this.addMarker(poi);
				}
			}
		}
		populatePOI();
		_this.plotRoute(true);
	}

	//Variable & Function for <phone-input>
	triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	updateForm(event) {
		this.form = event;
	}

	delete() {
		let _this = this;
		_this.isLoading = true;
		this.utils.delete(this.service, this.object.id, this.module.name, "/" + this.module.new_name).subscribe(res => {
			_this.isLoading = false;
		});
	}

	isPublished: boolean = false;
	publish() {
		let _this = this;
		this.utils.publish(this.service, this.object.id, this.module.name, "/" + this.module.new_name).subscribe(res => {
			_this.isPublished = false;
		});
		//_this.isLoading = true;
	}

	isPublishedInternally: boolean = false;
	publishInternally() {
		let _this = this;
		this.utils.publishinternally(this.service, this.object.id, this.module.name, "/" + this.module.new_name).subscribe(res => {
			_this.isPublishedInternally = false;
		});
		//_this.isLoading = true;
	}

	isUnpublished: boolean = false;
	unpublish() {
		let _this = this;
		this.utils.unpublish(this.service, this.object.id, this.module.name, "/" + this.module.new_name).subscribe(res => {
			_this.isUnpublished = false;
		});
		//_this.isLoading = true;
	}

	isUnpublishedInternally: boolean = false;
	unpublishInternally() {
		let _this = this;
		this.utils.unpublishinternally(this.service, this.object.id, this.module.name, "/" + this.module.new_name).subscribe(res => {
			_this.isUnpublishedInternally = false;
		});
		//_this.isLoading = true;
	}

	isFormValueChecked(controlName) {
		return this.form.get(controlName).value;
	}

	@Input() isLoading: boolean = false;


	@ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];
	save() {
		let _this = this;
		// console.log(this.activePolygon);
		// return;

		let trail = [];
		let pois = [];
		let order_number = 0;
		// _this.getRouteDistance();
		if (_this.activePolyline) {
			for (let segment of (isArray(_this.activePolyline._latlngs[0]) ? _this.activePolyline._latlngs[0] : _this.activePolyline._latlngs)) {
				trail.push({
					order_number: order_number,
					latitude: segment.lat,
					longitude: segment.lng
				});
				order_number++;
			}
		}
		// if (_this.stops && _this.stops.length > 0) {
		//   for (let poi of _this.stops) {
		//     if(poi['poi_data'] && poi['poi_data']['image']){
		//       pois.push(poi['poi_data']);
		//     }
		//   }
		// }

		if (_this.stops && _this.stops.length > 0) {
			for (let poi of _this.stops) {
				if (poi['poi_data']['title']) {
					pois.push(poi['poi_data']);
				}
			}
		}

		let filteredPOI: any[] = [];
		for (let poi of pois) {
			if (poi['type'] && poi['type']['id']) {
				let poiAlt = {...poi}; // = { ...poi, type: poi['type']['id'] };
				if(poi['advert'] && poi['advert']['id'])
				{
					poiAlt = { ...poiAlt, advert: poi['advert']['id'] };

				}
				if(poi['pilgrim_guide'] && poi['pilgrim_guide']['id'])
				{
					poiAlt = { ...poiAlt, pilgrim_guide: poi['pilgrim_guide']['id'] };
				}
				// poi['type'] = poi['type']['id'];
				poiAlt = { ...poiAlt, type: poi['type']['id'] };
				filteredPOI = [...filteredPOI, poiAlt];
			}
			else {
				filteredPOI = [...filteredPOI, poi];
			}
		}

		_this.form.get("trail_points").setValue(trail);
		_this.form.get("poi").setValue(filteredPOI);

		let gallery = _this.form.get('gallery').value;
		let galleryFiltered = [];
		if (gallery && gallery.length > 0) {
			for (let img of gallery) {
				galleryFiltered.push((img.thumbUrl ? img.thumbUrl : img.path ? img.path : img));
			}
		}
		_this.form.get("gallery").setValue(galleryFiltered);


		_this.isLoading = true;
		Utils.forceTouchPaginatedSelects(_this.paginatedSelects);
		Utils.handleFormSave(this.form, this.service, this.object, this.module.name, this.createForm, null).subscribe(res => {
			_this.isLoading = false;
			if (res['data']) {
				if (_this.external == true) {

					_this.saveEvent.emit(res['data']);

				}
				else {
					this.router.navigate(['/' + this.module.new_name + '/' + res['data']['id']]);
				}
				_this.utils.successMessage(_this.module.name + ' saved.');
			}
			else {
				Utils.sendFormSaveError(res, this.module, this.utils);
			}
		})
	}
}