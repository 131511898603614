import { Routes } from '@angular/router';
//Layouts
import { 
  //CondensedComponent,
  BlankComponent,
  //CorporateLayout,
  SimplyWhiteLayout,
  //ExecutiveLayout,
  //CasualLayout ,
  //BlankCasualComponent,
  //BlankCorporateComponent,
  BlankSimplywhiteComponent
} from './@pages/layouts';
import { AuthGaurd } from './shared/guards/auth-guard.service';
import { RoleGuard } from './shared/guards/role-guard';
import { GroupModule } from './view/user/groups/groups.module';
import { ProfileModule } from './view/profile/profile.module';
import { UserModule } from './view/user/user.module';
import { DashboardModule } from './view/dashboard/dashboard.module';
import { DeviceModule } from './view/device/device.module';
import { RouteModule } from './view/route/route.module';
import { POITypeModule } from './view/route/poi-type/poi-type.module';
import { AdvertModule } from './view/advert/advert.module';
import { CaminoModule } from './view/camino/camino.module';
import { PilgrimGuideModule } from './view/pilgrim-guide/pilgrim-guide.module';
import { StaticPageModule } from './view/static-pages/static-pages.module';
import { CertificateModule } from './view/certificate/certificate.module';


export const AppRoutes: Routes = [

  {
    path: 'user',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_user'
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/user/user.module').then(m => UserModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'camino',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_camino'
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/camino/camino.module').then(m => CaminoModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'group',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_group'
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/user/groups/groups.module').then(m => GroupModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'advert',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_advert'
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/advert/advert.module').then(m => AdvertModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'pilgrim-guide',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_pilgrim_guide'
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/pilgrim-guide/pilgrim-guide.module').then(m => PilgrimGuideModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'static-page',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_static_page'
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/static-pages/static-pages.module').then(m => StaticPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'certificate',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_static_page'//TODO - Change to view_certificate when permission gets added.
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/certificate/certificate.module').then(m => CertificateModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'device',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_device'
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/device/device.module').then(m => DeviceModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'route',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_route'
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/route/route.module').then(m => RouteModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'poitype',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_route_poi_type'
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/route/poi-type/poi-type.module').then(m => POITypeModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'profile',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:''
    },
    component: SimplyWhiteLayout,
    loadChildren:() => import('./view/profile/profile.module').then(m => ProfileModule),//'./view/profile/profile.module#ProfileModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'dashboard',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'generate_report'
    },
    component: SimplyWhiteLayout,
    loadChildren:() => import('./view/dashboard/dashboard.module').then(m => DashboardModule),//'./view/dashboard/dashboard.module#DashboardModule',
    runGuardsAndResolvers: 'always'
  },
  // {
  //   path: 'timesheettype',
  //   canActivate: [AuthGaurd,RoleGuard],
  //   data: {
  //     permission:'view_timesheet'
  //   },
  //   component: SimplyWhiteLayout,
  //   loadChildren:() => import('./view/timesheet/type/type.module').then(m => TypeModule),//'./view/dashboard/dashboard.module#DashboardModule',
  //   runGuardsAndResolvers: 'always'
  // },
  // {
  //   path: 'employee',
  //   canActivate: [AuthGaurd,RoleGuard],
  //   data: {
  //     permission:'view_employee'
  //   },
  //   component: SimplyWhiteLayout,
  //   loadChildren:() => import('./view/employee/employee.module').then(m => EmployeeModule),//'./view/dashboard/dashboard.module#DashboardModule',
  //   runGuardsAndResolvers: 'always'
  // },
  // {
  //   path: 'workcategory',
  //   canActivate: [AuthGaurd,RoleGuard],
  //   data: {
  //     permission:'view_project'
  //   },
  //   component: SimplyWhiteLayout,
  //   loadChildren:() => import('./view/works/works-categories/works-categories.module').then(m => WorksCategoryModule),//'./view/dashboard/dashboard.module#DashboardModule',
  //   runGuardsAndResolvers: 'always'
  // },
  {
    path: 'session',
    data: {
      permission:''
    },
    component: BlankSimplywhiteComponent,
    loadChildren: "./view/session/session.module#SessionModule"
  },
  // {
  //   path: 'menuselect',
  //   pathMatch:'full',
  //   canActivate: [AuthGaurd,RoleGuard],
  //   data: {
  //     permission:''
  //   },
  //   component: SimplyWhiteLayout,
  //   runGuardsAndResolvers: 'always'
  // },
  {
    path: '',
    pathMatch:'full',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:''
    },
    component: SimplyWhiteLayout,
    runGuardsAndResolvers: 'always'
  }
];
