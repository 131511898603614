import { FormBuilder, FormGroup, Validators } from "@angular/forms";

export class PilgrimGuideModel {

    id: number;
    title:string;
    description:string;
    image:string;

    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.title = input.title || '';
        this.description = input.description || '';
        this.image = input.image || '';
    }

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();
        return fb.group({
            title: [input.title,Validators.required],
            description: [input.description,Validators.required],
            image: [input.image],
        })
    }

    static getListRow(row: PilgrimGuideModel) {
        return {
            id: row.id,
            title:row.title
        };
    }

}