import { Routes } from '@angular/router';
import { AuthGaurd } from '../../../shared/guards/auth-guard.service';
import { RoleGuard } from '../../../shared/guards/role-guard';
import { POITypeDetailComponent } from './poi-type-detail/poi-type-detail.component';
import { POITypeListComponent } from './poi-type-list/poi-type-list.component';

export const DeviceRoutes: Routes = [
  {
    path: '',
    component: POITypeListComponent
  },
  {
    path: 'new',
    canActivate: [AuthGaurd,RoleGuard],
    component: POITypeDetailComponent
  },
  {
    path: ':id',
    canActivate: [AuthGaurd,RoleGuard],
    component: POITypeDetailComponent
  },
];
