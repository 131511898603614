import { Component, ElementRef, Inject, OnInit, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { MessageConfig, _MESSAGE_CONFIG, _MESSAGE_DEFAULT_CONFIG } from './message-config';
import { MessageDataFilled, MessageDataOptions } from './message.definitions';
import * as $ from 'jquery';
import { Responses, Types } from '../../../../constants';

@Component({
  selector: 'pg-message-container',
  encapsulation: ViewEncapsulation.None,
  templateUrl: `./message-container.component.html`,
  styleUrls: []
})
export class MessageContainerComponent {
  messages: MessageDataFilled[] = [];
  currentMessage: MessageDataFilled = null;
  style;
  config: MessageConfig;
  hasMessages: boolean = false;
  constructor(
    @Optional() @Inject(_MESSAGE_DEFAULT_CONFIG) defaultConfig: MessageConfig,
    @Optional() @Inject(_MESSAGE_CONFIG) config: MessageConfig
  ) {
    this.config = { ...defaultConfig, ...config };
    console.log(this.currentMessage);
  }

  // Create a new message
  createMessage(message: MessageDataFilled): void {
    let el = window.document.querySelector('.header ');
    if (el) {
      let rect = el.getBoundingClientRect();
      // this.style = {
      //   marginTop: rect.height + 'px'
      // };
    }
    if (message.options) {
      if(message.options.Type){
        switch (message.options.Type) {
          case Types.warning:
            message.options.Position = 'top-right';
            break;
          case Types.intervention:
            message.options.Position = 'top-center';
            break;
          case Types.indicator:
            message.options.Position = 'bottom-right';
            break;
        }
      }
    }
    this.currentMessage = message;
    if (this.messages.length >= this.config.MaxStack) {
      this.messages.splice(0, 1);
    }
    $('.pgn-wrapper').removeClass('hide');
    if (message.options.Type == Types.intervention) {
      message.options.Duration = 0;
    }
    message.options = this._mergeMessageOptions(message.options);
    this.messages.push(message);
    this.hasMessages = true;
  }

  // Remove a message by messageId
  removeMessage(messageId: string): void {
    let _this = this;
    this.messages.some((message, index) => {
      if (message.messageId === messageId) {
        _this.messages.splice(index, 1);
        if (_this.messages.length == 0) {
          _this.hasMessages = false;
          setTimeout(() => {
            $('.pgn-wrapper').addClass('hide');
          }, 500);
        }
        return true;
      }
    });
  }

  hasBackdrop() {
    if (this.currentMessage.options.Type != Types.intervention) {
      return false;
    }
    if (this.messages.length == 0) {
      return false;
    }
    return true;
  }

  isIntervention() {
    return this.currentMessage.options.Type == Types.intervention;
  }

  // Remove all messages
  removeMessageAll(): void {
    if(this.messages){
      for(let m of this.messages){
        if(m.options){
          if(m.options.Actions){
            if(m.options.Actions.onIgnore){
              m.options.Actions.onIgnore.function(Responses.ignore);
            }
          }
        }
      }
    }
    this.messages = [];
    this.hasMessages = false;
    $('.pgn-wrapper').addClass('hide');
  }

  // Merge default options and cutom message options
  protected _mergeMessageOptions(options: MessageDataOptions): MessageDataOptions {
    const defaultOptions: MessageDataOptions = {
      Duration: this.config.Duration,
      Animate: this.config.Animate,
      PauseOnHover: this.config.PauseOnHover
    };
    return { ...defaultOptions, ...options };
  }
}
