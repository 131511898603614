import { Routes } from '@angular/router';
import { ProfileComponent } from './profile.component';
import { AuthGaurd } from '../../shared/guards/auth-guard.service';
import { RoleGuard } from '../../shared/guards/role-guard';

export const ProfileRoutes: Routes = [
  {
    path: '',
    component: ProfileComponent
  }
];
