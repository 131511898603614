import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class POITypeService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.baseUrl}/route/poi/type`,{params:searchParams});
    }

    get(poiTypeId){
      return this.http.get(`${environment.baseUrl}/route/poi/type/`+poiTypeId);
    }

    edit(poiTypeId,formData){
      return this.http.put(`${environment.baseUrl}/route/poi/type/`+poiTypeId,formData);
    }

    create(formData){
      return this.http.post(`${environment.baseUrl}/route/poi/type`,formData);
    }

    delete(poiTypeId){
      return this.http.delete(`${environment.baseUrl}/route/poi/type/`+poiTypeId);
    }

}
