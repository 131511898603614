import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PilgrimGuideService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.baseUrl}/pilgrim`,{params:searchParams});
    }

    get(deviceId){
      return this.http.get(`${environment.baseUrl}/pilgrim/`+deviceId);
    }

    edit(deviceId,formData){
      return this.http.put(`${environment.baseUrl}/pilgrim/`+deviceId,formData);
    }

    create(formData){
      return this.http.post(`${environment.baseUrl}/pilgrim`,formData);
    }

    delete(deviceId){
      return this.http.delete(`${environment.baseUrl}/pilgrim/`+deviceId);
    }

}
