import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Page } from '../../../shared/models/page.model';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import * as $ from 'jquery';
import { EmployeeModel } from '../../../shared/models/employee.model';
import { Utils } from '../../../shared/services/utils.service';
import { EmployeeBuildData } from '../employee.build-data';
import { PaginatedSelectComponent } from '../../components/paginated-select/paginated-select.component';
import * as moment from 'moment';
import { pgDatePickerComponent } from '../../../@pages/components/datepicker/datepicker.component';
import { ColumnModel } from '../../../shared/models/column.model';
import { UserService } from '../../../shared/services/user.service';

@Component({
  selector: 'app-employee-detail',
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.scss']
})
export class EmployeeDetailComponent implements OnInit {

  euroPrefix: any = Utils.euroPrefix;
  @Input() external: boolean = false;
  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
  employee: EmployeeModel;
  roles: any[];
  @ViewChild('deleteModal', { static: true }) modalDelete: ModalDirective;
  errors: String[] = [];
  data = new EmployeeBuildData();
  module = this.data.module;
  form: FormGroup;
  rateForm: FormGroup;
  @ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];

  constructor(
    private service : UserService,
    private router: Router,
    private snapshot: ActivatedRoute,
    private authService: AuthService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlingService,
    private utils: Utils,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    let _this = this;
    this.createForm({});
    this.createRateForm({});
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.messageService.remove();
      }
    });
    this.snapshot.paramMap.subscribe(res => {
      if (res['params']['id'] && !_this.external) {
        this.loadData(res['params']['id']);
      }
    })
  }

  menuItems: any[] = [
    { action: 'delete', message: 'Delete ' + this.module.name, class: '', icon: 'fal fa-trash', permissions: ['delete_' + this.module.permission] }
  ];

  handleButtonPress = (event) => {
    switch (event) {
      case "delete":
        this.modalDelete.show();
        break;
      default:
        //console.log(event);
        break;
    }
  }

  incomingCompanyEvent: EventEmitter<any> = new EventEmitter<any>();
  incomingPaginatedSelectValue(fieldName, value) {
    let _this = this;
    switch (fieldName) {
      case 'company':
        _this.form.get('employing_company').setValue(value);
        break;
    }
  }

  markTouched() {
    this.form.markAsTouched();
  }

  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;

  photoChangeEvent(fileInput: any) {
    let _this = this;
    Utils.handleFileChange(fileInput).subscribe(res => {
      let success = res.success;
      if (success) {
        _this.cardImageBase64 = res.cardImageBase64;
        _this.isImageSaved = res.isImageSaved;
        _this.form.get('photo').setValue(res.cardImageBase64);
      }
      else {
        console.log("Failed to load file.");
      }
    });
  }

  @ViewChild('fromPicker', { static: true }) fromPicker: pgDatePickerComponent;
  @ViewChild('toPicker', { static: true }) toPicker: pgDatePickerComponent;
  fromDate: Date = new Date();
  toDate: Date = moment(new Date()).add(1, 'hour').toDate();
  forceRefresh(dateEvent) {
    let _this = this;
    Utils.forceRefresh(dateEvent, this.fromDate, this.toDate, this.fromPicker, this.toPicker).subscribe(res => {
      _this.fromDate = res['fromDate'];
      _this.toDate = res['toDate'];
      _this.cdr.detectChanges();
    })
  }

  _disabledStartDate = (startValue) => {
    return Utils._disabledStartDate(startValue, this.fromDate, this.toDate);
  };

  _disabledEndDate = (endValue) => {
    return Utils._disabledEndDate(endValue, this.fromDate, this.toDate);
  };

  @ViewChild('fromPickerAlt', { static: true }) fromPickerAlt: pgDatePickerComponent;
  @ViewChild('toPickerAlt', { static: true }) toPickerAlt: pgDatePickerComponent;
  fromDateAlt: Date = new Date();
  toDateAlt: Date = moment(new Date()).add(1, 'hour').toDate();
  forceRefreshAlt(dateEvent) {
    let _this = this;
    Utils.forceRefresh(dateEvent, this.fromDateAlt, this.toDateAlt, this.fromPickerAlt, this.toPickerAlt).subscribe(res => {
      _this.fromDateAlt = res['fromDate'];
      _this.toDateAlt = res['toDate'];
      _this.cdr.detectChanges();
    })
  }

  _disabledStartDateAlt = (startValue) => {
    return Utils._disabledStartDate(startValue, this.fromDateAlt, this.toDateAlt);
  };

  _disabledEndDateAlt = (endValue) => {
    return Utils._disabledEndDate(endValue, this.fromDateAlt, this.toDateAlt);
  };

  createForm(input, form?) {
    input = input || {};
    form ? form : this.form = EmployeeModel.getFormGroup(input);
  }

  createRateForm(input) {
    let _this = this;
    _this.rateForm = EmployeeModel.getRateFormGroup(input);
  }

  openFileUpload(ele) {
    $(ele).click();
  }

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  loading = {
    isFirstLoad: false,
    isLoading: false
  };

  loadData(id) {
    let _this = this;
    let checkLoadingSpinner = () => {
      if (!_this.loading.isFirstLoad) {
        _this.loading.isFirstLoad = true;
        _this.loading.isLoading = true;
      }
    }

    checkLoadingSpinner();

    this.service.get(id).subscribe(res => {
      _this.employee = res['data'];
      if (_this.employee.rate) {
        _this.isOvertimePayable = _this.employee.rate.is_overtime_payable;
      }
      //_this.loadRates();
      _this.loadDocuments();
      _this.createForm(_this.employee);
      _this.createRateForm(_this.employee.rate);
      if (res['data']['employing_company']) {
        _this.incomingCompanyEvent.emit(res['data']['employing_company']);
      }
      _this.triggerFetch.emit(_this.form);
      setTimeout(() => {
        _this.triggerFetchRate.emit(_this.rateForm);
      }, 1000);
      _this.loading.isLoading = false;
    },
      err => {
        _this.loading.isLoading = false;
        console.log(err);
      });
  }

  //Variable & Function for <phone-input>
  triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  triggerFetchRate: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  updateForm(event) {
    this.form = event;
  }

  delete() {
    let _this = this;
    _this.isLoading = true;
    this.utils.delete(this.service, this.employee.id, this.module.name, "/" + this.module.new_name).subscribe(res => {
      _this.isLoading = false;
    });
  }

  isFormValueChecked(controlName) {
    return this.form.get(controlName).value;
  }

  @Input() isLoading: boolean = false;

  save() {
    let _this = this;
    _this.isLoading = true;

    Utils.forceTouchPaginatedSelects(this.paginatedSelects);
    Utils.handleFormSave(this.form, this.service, this.employee, this.module.name, this.createForm, null).subscribe(res => {
      _this.isLoading = false;
      if (res['data']) {
        if (_this.external == true) {
          _this.saveEvent.emit(res['data']);
        }
        else {
          this.router.navigate(['/' + this.module.new_name + '/' + res['data']['id']]);
        }
        _this.utils.successMessage(_this.module.name + ' saved.');
      }
      else {
        Utils.sendFormSaveError(res,this.module,this.utils);
      }
    })
  }

  canUpdateRate(date_from?:string):boolean{
    let _this = this;
    if(date_from){
      if(moment(_this.employee.rate.date_from).isSameOrAfter(moment(date_from))){
        return false;
      }
    }
    if(this.rateToUpdate){
      if(moment(_this.employee.rate.date_from).isSameOrAfter(moment(_this.rateToUpdate.date_from))){
        return false;
      }
    }
    return true;
  }

  clearRateUpdate() {
    let _this = this;
    _this.rateToUpdate = null;
    _this.createRateForm(_this.employee.rate);
    _this.triggerFetchRate.emit(_this.rateForm);
  }

  onReloadRate: EventEmitter<boolean> = new EventEmitter<boolean>();
  isOvertimePayable: boolean = false;
  saveRate() {
    let _this = this;
    _this.isLoading = true;

    let rawRateForm = this.rateForm.getRawValue();

    if(!_this.canUpdateRate()){
      _this.utils.errorMessage("Rates prior to active rate cannot be updated.");
      _this.isLoading = false;
      return;
    }

    if(_this.employee.rate){
      if(moment(_this.employee.rate.date_from).isSameOrAfter(moment(rawRateForm['date_from']))){
        _this.utils.errorMessage("Date from cannot be before currently active rate.");
        _this.isLoading = false;
        return;
      }
    }

    if (this.rateForm.invalid) {
      _this.rateForm.markAllAsTouched();
      _this.utils.errorMessage("Rate Form is Invalid");
      _this.isLoading = false;
      //console.log(_this.rateForm);
      return;
    }

    rawRateForm['date_from'] = moment(rawRateForm['date_from']).format();
    rawRateForm['is_overtime_payable'] = _this.isOvertimePayable;

    // (this.rateToUpdate ? this.rateService.updateRate(_this.employee.id, _this.rateToUpdate.id, rawRateForm) : this.rateService.createRate(_this.employee.id, rawRateForm)).subscribe(res => {
    //   //console.log(res);
    //   if (_this.rateToUpdate) {
    //     _this.rateToUpdate = null;
    //     _this.createRateForm(_this.employee.rate);
    //     _this.isOvertimePayable = _this.employee.rate.is_overtime_payable;
    //     _this.triggerFetchRate.emit(_this.rateForm);
    //     _this.onReloadRate.emit(true);
    //     _this.utils.successMessage('Rate updated.<br> Switched Back to Active Rate.');
    //   }
    //   else {
    //     _this.onReloadRate.emit(true);
    //     _this.utils.successMessage('Rate saved.');
    //   }
    //   _this.isLoading = false;
    // },
    //   err => {
    //     _this.utils.errorMessage('Rate save failed.');
    //     console.log(err);
    //     _this.isLoading = false;
    //   })

    // this.utils.handleFormSave(this.rateForm, this.service, this.employee, this.module.name, this.createRateForm, null).subscribe(res => {
    //   _this.isLoading = false;
    //   if (res['data']) {
    //     _this.utils.successMessage('Rate saved.');
    //   }
    //   else {
    //     _this.utils.errorMessage('Rate save failed.');
    //   }
    // })
  }

  //Documents

  documentMenuItems: any[] = [
    { action: 'delete', message: 'Delete Document', class: '', icon: 'fal fa-trash' }
  ];

  @ViewChild('deleteDocumentModal', { static: true }) modalDeleteDocument: ModalDirective;
  activeDocument: any;
  handleButtonPressDocument = (event, row) => {
    //console.log(row);
    this.activeDocument = row;
    switch (event) {
      case "delete":
        this.modalDeleteDocument.show();
        break;
      default:
        console.log(event);
        break;
    }
  }

  file: {
    data: string;
    type: string;
    description: string;
  }

  // fileChangeEvent(fileInput: any) {
  //   let _this = this;
  //   this.utils.handleFileChange(fileInput).subscribe(res => {
  //     let success = res.success;
  //     if (success) {
  //       _this.cardImageBase64 = res.cardImageBase64;
  //       _this.isImageSaved = res.isImageSaved;
  //       _this.file = {
  //         data: res.cardImageBase64,
  //         type:"",
  //         description:""
  //       }
  //       console.log(res);
  //       //_this.form.get('photo').setValue(res.cardImageBase64);
  //     }
  //     else {
  //       console.log("Failed to load file.");
  //     }
  //   });
  // }

  savedEvent: any;
  fileData: {
    name: string,
    type: string
  }
  docDesc: string;
  previewUpload: any[];
  beginFileUpload(event) {
    let _this = this;
    this.savedEvent = event;
    //console.log(event.target.files[0]);

    let fileSplitter = [...event.target.files[0].name.split(".")];

    this.fileData = {
      name: fileSplitter[0],
      type: fileSplitter[1]
    };

    var reader = new FileReader();
    reader.onload = (event: any) => {
      _this.previewUpload = event.target.result;
    }
    reader.readAsDataURL(event.target.files[0]);
  }

  @ViewChild("uploadDocumentModal", { static: false }) uploadDocument: ModalDirective;
  uploadFile(documentDescription) {
    if (!this.previewUpload) {
      return;
    }
    if (this.descriptionTouched(documentDescription) || !this.docDesc) {
      $(documentDescription).addClass('ng-touched');
      return;
    }
    let _this = this;
    this.isLoading = true;
    //console.log(this.savedEvent);
    if (this.savedEvent.target.files && this.savedEvent.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e: any) {
        let data = {
          data: e.target.result,
          description: _this.docDesc,
          name: _this.fileData.name,
          type: _this.fileData.type
        };
        // _this.service.createFile(_this.employee.id, data).subscribe(res => {
        //   _this.messageService.success("Document Uploaded.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        //   _this.loadDocuments();
        //   _this.uploadDocument.hide();
        //   _this.docDesc = "";
        //   _this.fileData = null;
        //   _this.previewUpload = null;
        //   _this.isLoading = false;
        // },
        //   err => {
        //     _this.messageService.error("Document Upload Failed.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
        //     console.log(err);
        //     _this.isLoading = false;
        //   });
      }
      reader.readAsDataURL(this.savedEvent.target.files[0]);
    }
  }

  onReload: EventEmitter<boolean> = new EventEmitter<boolean>();
  loadDocuments() {
    this.onReload.emit(true);
  }

  descriptionTouched(documentDescription) {
    if ($(documentDescription).hasClass('ng-touched') && $(documentDescription).hasClass('ng-invalid')) {
      return true;
    }
    return false;
  }

  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
  }

  rowFunction = (row: any) => {//Generates Row for codeblack-table
    return {
      id: row.id,
      name: row.file_path,
      file_type: row.file_type,
      description: row.description,
      created_by: row.created_by.first_name + ' ' + row.created_by.last_name,
      created_at: moment(row.created_at).format("DD/MM/YYYY HH:mm"),
      customLink: row.file_path
    };
  }
  columns: ColumnModel[] = [
    {
      title: "Description",
      prop: "description",
      flex_grow: 30,
      row_accessor: "description",
    },
    {
      title: "Type",
      prop: "file_type",
      flex_grow: 10,
      row_accessor: "file_type"
    },
    {
      title: "Created At",
      prop: "created_at",
      flex_grow: 25,
      row_accessor: "created_at"
    },
    {
      title: "Created By",
      prop: "created_by",
      flex_grow: 25,
      row_accessor: "created_by"
    },
    {
      title: "Actions",
      prop: "",
      flex_grow: 10,
      row_accessor: "actions",
      html_data: true
    }
  ];

  columnsRate: ColumnModel[] = [
    {
      title: "Date From",
      prop: "date_from",
      flex_grow: 22,
      row_accessor: "date_from",
    },
    {
      title: "Net Pay Hourly",
      prop: "net_pay_hourly",
      flex_grow: 22,
      row_accessor: "net_pay_hourly",
    },
    {
      title: "Net Pay Monthly",
      prop: "net_pay_monthly",
      flex_grow: 22,
      row_accessor: "net_pay_monthly",
    },
    {
      title: "Gross Pay Monthly",
      prop: "gross_pay_monthly",
      flex_grow: 22,
      row_accessor: "gross_pay_monthly",
    },
    {
      title: "Can Edit",
      prop: "",
      flex_grow: 12,
      row_accessor: "can_edit",
      html_data:true
    }
  ];

  rowFunctionRate = (row: any) => {//Generates Row for codeblack-table
    let _this = this;
    return {
      id: row.id,
      date_from: moment(row.date_from).format("DD/MM/YYYY"),
      net_pay_hourly: '€' + Utils.formatNumbersToFixedWithCommas(row.net_pay_hourly),
      net_pay_monthly: '€' + Utils.formatNumbersToFixedWithCommas(row.net_pay_monthly),
      gross_pay_monthly: '€' + Utils.formatNumbersToFixedWithCommas(row.gross_pay_monthly),
      can_edit:`<span class='codeblack-anchor' data-flow="top">
      ${_this.canUpdateRate(row.date_from) ? `<i class="fal fa-check green"></i>` : `<i class="fal fa-times red"></i>`}
      </span>`
    };
  }

  rateToUpdate: any;

  selectRate(rate) {
    let _this = this;
    let rateId = rate['id'];
    // this.rateService.get(_this.employee.id, rateId).subscribe(res => {
    //   _this.rateToUpdate = res['data'];
    //   _this.isOvertimePayable = _this.rateToUpdate.is_overtime_payable;
    //   _this.createRateForm(_this.rateToUpdate);
    //   _this.triggerFetchRate.emit(_this.rateForm);
    // },
    //   err => {
    //     console.log(err);
    //   });
  }

  deleteFile() {
    let _this = this;
    _this.isLoading = true;
    // _this.service.deleteFile(_this.employee.id, _this.activeDocument.id).subscribe(res => {
    //   _this.modalDeleteDocument.hide();
    //   _this.utils.successMessage("Document Deleted.");
    //   _this.loadDocuments();
    //   _this.isLoading = false;
    // },
    //   err => {
    //     _this.utils.errorMessage("Document Delete Failed.");
    //     console.log(err);
    //     _this.isLoading = false;
    //   })
  }

}
