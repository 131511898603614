import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { DeviceModel } from "./device.model";
import { PointOfInterestModel, RouteModel, TrailPointModel } from "./route.model";
import { UserModel } from "./user.model";
import * as L from 'leaflet';
import { RoutePointOfInterestModel } from "./route-poi.model";

export class CaminoModel {

    
    id: number;
    user: UserModel;
    device: DeviceModel;
    route: RouteModel;
    poi: RoutePointOfInterestModel[];
    trail: TrailPointModel[];
    start_at:string;
    finish_at:string;
    pause_start_at:string;
    pause_finish_at:string;
    is_completed:boolean;
    route_poi_count:number;

    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.user = input.user || {};
        this.device = input.device || {};
        this.route = input.route || {};
        this.poi = input.poi || [];
        this.trail = input.trail || [];
        this.start_at = input.start_at || '';
        this.finish_at = input.finish_at || '';
        this.pause_start_at = input.pause_start_at || '';
        this.pause_finish_at = input.pause_finish_at || '';
        this.is_completed = input.is_completed || '';
        this.route_poi_count = input.route_poi_count || '';
    }

    getCompletedPOICount(){
        return this.poi.filter(val => {
            if(val.route_poi.type && val.route_poi.id != 5){
                return val;
            }
        }).length;
    }

    getDuration(){
        let duration = 0;
        if(this.pause_start_at){
            duration += moment.duration(moment(this.pause_start_at).diff(moment(this.start_at))).asMinutes();
            //console.log("1:",moment.duration(moment(this.pause_start_at).diff(moment(this.start_at))).asMinutes());
        }
        if(this.pause_finish_at && !this.finish_at){
            duration += moment.duration(moment().diff(moment(this.pause_start_at))).asMinutes();
            //console.log("2",moment.duration(moment(this.pause_finish_at).diff(moment())).minutes());
        }
        if(this.pause_finish_at && this.finish_at){
            duration += moment.duration(moment(this.finish_at).diff(moment(this.pause_start_at))).asMinutes();
            //console.log("3",moment.duration(moment(this.pause_finish_at).diff(moment(this.finish_at))).minutes());
        }
        return duration.toFixed(0);
    }

    getDurationSplit(){
        let duration = 0;
        if(this.pause_start_at){
            duration += moment.duration(moment(this.pause_start_at).diff(moment(this.start_at))).asMinutes();
            //console.log("1:",moment.duration(moment(this.pause_start_at).diff(moment(this.start_at))).asMinutes());
        }
        if(this.pause_finish_at && !this.finish_at){
            duration += moment.duration(moment().diff(moment(this.pause_start_at))).asMinutes();
            //console.log("2",moment.duration(moment(this.pause_finish_at).diff(moment())).minutes());
        }
        if(this.pause_finish_at && this.finish_at){
            duration += moment.duration(moment(this.finish_at).diff(moment(this.pause_start_at))).asMinutes();
            //console.log("3",moment.duration(moment(this.pause_finish_at).diff(moment(this.finish_at))).minutes());
        }

        let hours = Math.floor((duration / 60));
        let minutes = (duration - (hours * 60)).toFixed(0);
        return {hours:hours,minutes:minutes};
    }

    //Speed is in meter / minute
    getSpeed(){
        let durationMinutes = +this.getDuration();
        let distanceMeter = +this.getDistance();
        let distanceOverTime = ((distanceMeter / 1000) / (durationMinutes / 60));
        return distanceOverTime.toFixed(2)+' Km/h';
    }

    getDistance(): number {
      let calculateDistance = (_latlngs) => {
        // distance in meters

        var mDistance = 0,
          length = _latlngs.length;
        for (var i = 1; i < length; i++) {
            //mDistance += L.GeometryUtil.length([_latlngs[i],_latlngs[i - 1]]);
            mDistance += L.latLng(_latlngs[i].latitude,_latlngs[i].longitude).distanceTo(L.latLng(_latlngs[i - 1].latitude,_latlngs[i - 1].longitude));
        }
        return mDistance;
      }
      let distance = calculateDistance(this.trail);
      return distance;
    }

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();
        return fb.group({
            user: [input.user,Validators.required],
            device: [input.device,Validators.required],
            route: [input.route,Validators.required],
            poi: [input.poi,Validators.required],
            trail: [input.trail,Validators.required],
            start_at: [input.start_at,Validators.required],
            finish_at: [input.finish_at,Validators.required],
            pause_start_at: [input.pause_start_at,Validators.required],
            pause_finish_at: [input.pause_finish_at,Validators.required],
            is_completed: [input.is_completed,Validators.required],
        })
    }

    static getListRow(row: CaminoModel) {
        return {
            id: row.id,
            device_id:row.device.device_id,
            is_completed:`<span class='codeblack-anchor' data-flow="top">
            ${row.is_completed ? `<i class="fal fa-check green"></i>` : `<i class="fal fa-times red"></i>`}
            </span>`,
            is_paused: (row.pause_start_at && !row.pause_finish_at),
            poi:row.poi,
            route:row.route.title,
            user:row.user,
            user_fullname: (row.user) ? row.user.first_name + ' ' + row.user.last_name : 'Guest',
            start_at:moment(row.start_at).format("DD/MM/YYYY HH:mm"),
            finish_at:row.finish_at
        };
    }

}