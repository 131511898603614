import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Utils } from "../services/utils.service";

class EmployeeRateModel {

  id: number;
  employee: EmployeeModel;
  date_from:string;
  net_pay_hourly: string;
  net_pay_monthly: string;
  gross_pay_monthly: string;
  overtime_pay: string;
  is_overtime_payable: boolean;

  constructor(input?) {
    input = input || {};
    this.id = input.id || '';
    this.employee = input.employee || '';
    this.net_pay_hourly || '';
    this.net_pay_monthly || '';
    this.gross_pay_monthly || '';
    this.overtime_pay = input.overtime_pay || '';
    this.is_overtime_payable = input.is_overtime_payable || '';
  }

}

export class EmployeeModel {
  last_name: string;//
  first_name: string;//
  email: string;//
  foreign_id_card: string;//
  maltese_id_card: string;//
  maltese_id_issued_at: string;//
  maltese_id_expiration_at: string;//
  passport_number: string;//
  ni_number: string;//
  tax_number: string;//
  address_1: string;//
  address_2: string;//
  post_code: string;//
  country: string;//
  phone_number: string;//
  phone_mobile: string;//
  designation: string;//
  employing_company: any;//
  iban: string;//
  swift: string;//
  engagement_date: string;//
  termination_date: string;//
  id: number;//
  photo: string;//
  deleted_at: string;//
  created_at: string;//
  updated_at: string;//
  employee_type: string;//
  rate: EmployeeRateModel;//

  constructor(input?) {
    input = input || {};
    this.id = input.id || '';
    this.first_name = input.first_name || '';
    this.last_name = input.last_name || '';
    this.email = input.email || '';
    this.foreign_id_card = input.foreign_id_card || '';
    this.passport_number = input.passport_number || '';
    this.maltese_id_card = input.maltese_id_card || '';
    this.maltese_id_issued_at = input.maltese_id_issued_at || '';
    this.maltese_id_expiration_at = input.maltese_id_expiration_at || '';
    this.ni_number = input.ni_number || '';
    this.tax_number = input.tax_number || '';
    this.address_1 = input.address_1 || '';
    this.address_2 = input.address_2 || '';
    this.post_code = input.post_code || '';
    this.country = input.country || '';
    this.phone_number = input.phone_number || '';
    this.phone_mobile = input.phone_mobile || '';
    this.designation = input.designation || '';
    this.employing_company = input.employing_company || '';
    this.iban = input.iban || '';
    this.swift = input.swift || '';
    this.updated_at = input.updated_at || '';
    this.created_at = input.created_at || '';
    this.deleted_at = input.deleted_at || '';
    this.employee_type = input.employee_type || '';
    this.engagement_date = input.engagement_date || '';
    this.termination_date = input.termination_date || '';
    this.photo = input.photo || '';
    this.rate = input.rate || {};
  }

  static getGroupData = (input) => {//[input.first_name, Validators.required],
    return {
      last_name: [input.last_name, Validators.required],//
      first_name: [input.first_name, Validators.required],//
      email: [input.email, Validators.required],//
      foreign_id_card: [input.foreign_id_card],//
      maltese_id_card: [input.maltese_id_card],//
      maltese_id_issued_at: [input.maltese_id_issued_at],//
      maltese_id_expiration_at: [input.maltese_id_expiration_at],//
      passport_number: [input.passport_number],//
      ni_number: [input.ni_number],//
      tax_number: [input.tax_number],//
      address_1: [input.address_1],//
      address_2: [input.address_2],//
      post_code: [input.post_code],//
      country: [input.country],//
      phone_number: [input.phone_number],//
      phone_mobile: [input.phone_mobile],//
      designation: [input.designation],
      employing_company: [input.employing_company, Validators.required],//
      iban: [input.iban],//
      swift: [input.swift],//
      engagement_date: [input.engagement_date],//
      termination_date: [input.termination_date],//
      photo: [input.photo],//
      deleted_at: [input.deleted_at],
      created_at: [input.created_at],
      updated_at: [input.updated_at],
      employee_type: [input.employee_type]//
    }
  };

  static getRateData = (input) => {
    input = input || {};
    return {
      date_from:[input.date_from,Validators.required],
      net_pay_hourly: [input.net_pay_hourly,Validators.required],
      net_pay_monthly: [input.net_pay_monthly,Validators.required],
      gross_pay_monthly: [input.gross_pay_monthly,Validators.required],
      overtime_pay: [input.overtime_pay],
      is_overtime_payable: [input.is_overtime_payable],
    }
  }

  static getRateFormGroup(input):FormGroup{
    let fb: FormBuilder = new FormBuilder();
    return fb.group(this.getRateData(input));
  }

  static getFormGroup(input): FormGroup {
    let fb: FormBuilder = new FormBuilder();
    return fb.group(this.getGroupData(input));
  }

  static getAddress(row:EmployeeModel){
    return Utils.getFormattedAddress(row);
  }

  static getListRow(row: EmployeeModel) {
    return {
      id: row.id,
      name: row.first_name + ' ' + row.last_name,
      address: this.getAddress(row) ? this.getAddress(row) : 'N/A',
      email: row.email ? row.email : 'N/A',
      phone: row.phone_number ? row.phone_number : 'N/A'
    };
  }

}
