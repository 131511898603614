import { Component, EventEmitter, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../shared/services/user.service';
import { MessageService } from '../../@pages/components/message/message.service';
import { UserModel } from '../../shared/models/user.model';
import { Router, NavigationStart } from '@angular/router';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';
import { LocalStoreService } from '../../shared/services/local-store.service';
import { Location } from '@angular/common';
import { Utils } from '../../shared/services/utils.service';
import { ProfileService } from '../../shared/services/profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {

  user: UserModel;
  form: FormGroup;
  errors: String[] = [];
  constructor(
    private service: ProfileService,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlingService,
    private utils : Utils,
    private store: LocalStoreService,
    private _location: Location
  ) { }

  ngOnInit() {
    this.createForm({});
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.messageService.remove();
      }
    });
    this.loadData(this.authService.getUserId());
  }

  sendBack() {
    this._location.back();
  }

  markTouched() {
    this.form.markAsTouched();
  }

  createForm(input, form?) {
    input = input || {};
    form ? form : this.form = UserModel.getFormGroup(input); 
  }

  triggerFetch:EventEmitter<any> = new EventEmitter<any>();
  loadData(id) {
    let _this = this;

    this.service.get(id).subscribe(res => {
      _this.user = res['data'];
      _this.createForm(_this.user);
      _this.triggerFetch.emit(_this.form);
      _this.isLoading = false;
    },
      err => {
        _this.isLoading = false;
        console.log(err);
      });
  }

  isLoading:boolean = false;
  // save() {
  //   if (this.user) {
  //     this.userForm.get('password').clearValidators();
  //     this.userForm.get('password_confirmation').clearValidators();
  //     this.userForm.get('password').updateValueAndValidity();
  //     this.userForm.get('password_confirmation').updateValueAndValidity();
  //   }
  //   this.userForm.updateValueAndValidity();
  //   if (this.userForm.invalid) {
  //     this.userForm.markAllAsTouched();
  //     this.isLoading = false;
  //     return;
  //   }
  //   if (this.userForm.get('password').value != this.userForm.get('password_confirmation').value && (this.userForm.get('password').touched || this.userForm.get('password_confirmation').touched) && (this.userForm.get('password').value || this.userForm.get('password_confirmation').value)) {
  //     this.messageService.error("Passwords must match!", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
  //     this.isLoading = false;
  //     return;
  //   }

  //   if(this.isLoading){
  //     return;
  //   }

  //   this.isLoading = true;

  //   let _this = this;
  //   let rawValue = this.userForm.getRawValue();
  //   this.userForm.markAsUntouched();
  //   this.userForm.updateValueAndValidity();
  //   this.userService.updateProfile(rawValue).subscribe(res => {
  //     this.messageService.remove();
  //     this.messageService.success("User Updated.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
  //     _this.createForm(res['data']);
  //     const user = {..._this.store.getItem('currentUser'),...res['data']};
  //     const token = _this.store.getItem('currentUser')['token'];
  //     const id = res['data']['id'];
  //     //const role = res['data']['role'][0]['id'];
  //     //const company = res['data']['company'];
  //     // login successful if there's a jwt token in the response
  //     if (user && token) {
  //       // store user details and jwt token in local storage to keep user logged in between page refreshes
  //       let storeData = { ...user, token, id };
  //       console.log(res['data']);
  //       console.log(storeData);
  //       _this.authService.user = user;
  //       _this.store.setItem('currentUser', storeData);
  //       console.log(_this.store.getItem('currentUser'));
  //       _this.authService.authenticated = true;
  //     }
  //     _this.isLoading = false;
  //   },
  //     err => {
  //       _this.errors = _this.errorHandler.getErrors(err);
  //       this.messageService.remove();
  //       this.messageService.error(_this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
  //       console.log(err);
  //       _this.isLoading = false;
  //     });
  // }

  save() {
    let _this = this;
    _this.isLoading = true;
    console.log(this.form.getRawValue());
    Utils.handleFormSave(this.form, this.service, this.user, 'profile', this.createForm, null).subscribe(res => {
      _this.isLoading = false;
      if (res['data']) {
        let user = _this.store.getItem('currentUser');
        if(user && user['id'] == res['data']['id']){
          user['first_name'] = res['data']['first_name'];
          user['last_name'] = res['data']['last_name'];
          user['photo'] = res['data']['photo'];
          _this.store.setItem('currentUser',user);
        }
        _this.utils.successMessage('Profile saved.');
      }
      else{
        Utils.sendFormSaveError(res,'Profile',this.utils);
      }
    })
  }


  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;

  photoChangeEvent(fileInput: any) {
    let _this = this;
    Utils.handleFileChange(fileInput).subscribe(res => {
      let success = res.success;
      if (success) {
        _this.cardImageBase64 = res.cardImageBase64;
        _this.isImageSaved = res.isImageSaved;
        _this.form.get('photo').setValue(res.cardImageBase64);
      }
      else {
        console.log("Failed to load file.");
      }
    });
  }


}