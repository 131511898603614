import { FormBuilder, FormGroup, Validators } from "@angular/forms";

export class GroupModel {

    id:number;
    title:string;
    permissions:any[];

    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.title = input.title || '';
        this.permissions = input.permission || '';
    }

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();
        return fb.group({
            title: [input.title, Validators.required],
            permissions: [input.permissions, Validators.required]
        })
    }

    static getListRow(row: GroupModel) {
        return {
            id: row.id,
            title: row.title
        };
    }

}